import React, { useContext, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/UserContext";
import { postRequest } from "../backendServices/ApiCalls";
import Notification from "./Notification";

function RemoveWhitelistAddress({ isopen, onClose }) {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [faotp, setFaotp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const faotpRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [otpLoading, setOtpLoading] = useState(false);

  const Images = {
    close: "./icon/CDM.svg",
  };
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Focus next field
      if (index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (!otp[index]) {
        // Focus previous field if empty
        if (index > 0) {
          otpRefs.current[index - 1]?.focus();
        }
      } else {
        // Clear current field
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleOtpClick = (index) => {
    otpRefs.current[index]?.focus();
  };

  const handleFaotpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const updatedFaotp = [...faotp];
      updatedFaotp[index] = value;
      setFaotp(updatedFaotp);

      // Focus next field
      if (index < faotp.length - 1) {
        faotpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleFaotpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedFaotp = [...faotp];
      if (!faotp[index]) {
        // Focus previous field if empty
        if (index > 0) {
          faotpRefs.current[index - 1]?.focus();
        }
      } else {
        // Clear current field
        updatedFaotp[index] = "";
        setFaotp(updatedFaotp);
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      faotpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < faotp.length - 1) {
      faotpRefs.current[index + 1]?.focus();
    }
  };
  const handleOtpPaste = (e) => {
    e.preventDefault(); // Prevent default paste behavior
    const pastedData = e.clipboardData.getData("Text").slice(0, otp.length); // Limit to OTP length
    const otpArray = pastedData.split("");

    // Update the OTP state with the pasted values
    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      otpArray.forEach((char, idx) => {
        updatedOtp[idx] = char;
      });
      return updatedOtp;
    });
    const nextInput = document.querySelector(
      `input#otp-input-${otpArray.length}`
    );
    if (nextInput) {
      nextInput.focus();
    }
  };
  const handleFaotpClick = (index) => {
    faotpRefs.current[index]?.focus();
  };

  const handleConfirmation = () => {
    try {
      setLoading(true);

      if (!navigator.onLine) {
        Notification.showNotification(
          "error",
          "Network Error",
          "Please check your internet connection and try again."
        );
        setLoading(false);
        return;
      }

      postRequest(
        "yes",
        "api/bybit/removewithdrawalwhitelist",
        {
          otp: otp.join(""),
          faotp: faotp.join(""),
        },
        (response) => {
          if (response.status === 200) {
            Notification.showNotification(
              "success",
              "Success",
              "Withdrawal Address Removed Successfully "
            );
            setUser((prevUser) => ({
              ...prevUser,
              whitelistedaddress: "",
              whitelistedname: "",
            }));
            onClose();
          } else {
            // Handle unexpected responses
            const errorMessage =
              response.data?.error || "An unexpected error occurred.";
            Notification.showNotification("error", "Error", errorMessage);
          }
          setLoading(false);
        },
        (error) => {
          // Handle API request failures
          const errorMessage =
            error.response?.data?.error ||
            error.response?.data?.message ||
            error.message ||
            "An unexpected error occurred.";
          Notification.showNotification("error", "Error", errorMessage);
          setLoading(false);
        }
      );
    } catch (error) {
      // Handle unexpected runtime errors
      Notification.showNotification("error", "Error", error.message);
      console.error("Runtime Error:", error);
      setLoading(false);
    }
  };
  const handlewhitelistChange = () => {
    if (user.whitelistedaddress) {
      try {
        setOtpLoading(true);
        if (!navigator.onLine) {
          Notification.showNotification(
            "error",
            "Network Error",
            "Please check your internet connection and try again."
          );
          setOtpLoading(false);
          return;
        }
        postRequest(
          "yes",
          "api/bybit/removewithdrawalwhitelistotp",
          {},
          (response) => {
            if (response.status === 200) {
              Notification.showNotification(
                "success",
                "Success",
                "OTP has been sent to your email"
              );
            }
            setOtpLoading(false);
          }
        );
      } catch (error) {
        console.error("error", error);
      }
    }
  };
  const maskedEmail = (() => {
    const email = user?.email || "No email available";
    const [localPart, domainPart] = email.split("@");
    const firstPart = localPart.slice(0, 3);
    const maskedLocal = `${firstPart}***`;
    const maskedDomain =
      domainPart?.length > 3 ? `${domainPart.slice(0, 3)}***` : domainPart;
    return `${maskedLocal}@${maskedDomain}`;
  })();
  return (
    isopen && (
      <div className="fixed z-50 inset-0 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
        <div
          className={`bg-Accent p-4 rounded-lg w-[400px] sm:w-[738px] animate-fadeIn`}
        >
          <div className="bg-[#211E34E5] pt-6 border rounded-3xl border-[#FFFFFF26]">
            <div className="relative flex justify-between mx-[4%]">
              <p className="font-sora text-[16px] font-bold leading-[1.26] text-left">
                Withdraw Confirmation
              </p>
              <img
                src={Images.close}
                onClick={() => {
                  onClose();
                  setOtp("");
                  setFaotp("");
                }}
                className=" right-6 size-4 cursor-pointer"
                alt="close-icon"
              />
            </div>
            <p className="font-sans text-[#9B9B9B] text-[12px] font-normal leading-6 text-left ml-[4%]">
              Any questions? Find more information in our{" "}
              <Link to="/faqs">
                <span className="text-primary">FAQ</span>
              </Link>
            </p>
            <div className="bg-[#3F3C4F] rounded-lg my-6 mx-[4%] p-4">
              <p className="font-sora text-[16px] text-[#FFFFFFBF] font-bold leading-[20.16px] text-left">
                Authenticator code
              </p>
              <p className="font-helvetica text-[12px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                Please confirm your whitelist address by entering the 2FA code
              </p>
              <p className="font-title-medium font-sora mt-6 text-title-medium text-center">
                Enter 2FA Code
              </p>
              <div className="flex justify-center mt-2 space-x-2 sm:space-x-4">
                {faotp.map((digit, index) => (
                  <input
                    key={`faotp-${index}`}
                    ref={(el) => (faotpRefs.current[index] = el)}
                    type="text"
                    value={digit}
                    onChange={(e) => handleFaotpChange(e, index)}
                    onKeyDown={(e) => handleFaotpKeyDown(e, index)}
                    onClick={() => handleFaotpClick(index)}
                    maxLength="1"
                    className="h-10 w-10 sm:w-16 sm:h-16 border border-accent2 text-primary bg-transparent rounded-md text-center font-bold text-lg sm:text-2xl"
                  />
                ))}
              </div>
            </div>
            <hr className="mx-[4%] border-t border-[#FFFFFF26]" />
            <div className="bg-[#3F3C4F] rounded-lg mt-6 mx-[4%] p-4">
              <p className="font-sora text-[16px] text-[#FFFFFFBF] font-bold leading-[20.16px] text-left">
                One Time Password via E-Mail
              </p>
              <p className="font-helvetica text-[12px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                A verification code will be sent to{" "}
                <span className="text-primary">{maskedEmail}</span>{" "}
              </p>
              <p className="font-title-medium font-sora mt-6 text-title-medium text-center">
                Enter E-Mail Verification Code
              </p>
              <div className="flex justify-center mt-2 space-x-2 sm:space-x-4">
                {otp.map((digit, index) => (
                  <input
                    key={`otp-${index}`}
                    ref={(el) => (otpRefs.current[index] = el)}
                    type="text"
                    value={digit}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    onPaste={handleOtpPaste}
                    onClick={() => handleOtpClick(index)}
                    maxLength="1"
                    className="h-10 w-10 sm:w-16 sm:h-16 border border-accent2 text-primary bg-transparent rounded-md text-center font-bold text-lg sm:text-2xl"
                  />
                ))}
              </div>
              <div className=" items-center justify-center w-full flex  mt-4">
                {otpLoading ? (
                  <img
                    src="/icon/loaderwhite.svg"
                    alt="Loading..."
                    className="w-7 mx-auto h-7 animate-spin"
                  />
                ) : (
                  <div
                    className="bg-[#211E34E5] flex px-2 py-1 gap-1 cursor-pointer rounded-sm"
                    onClick={() => {
                      handlewhitelistChange();
                    }}
                  >
                    <img src="/icon/message1.png" className="size-4" />
                    <p className="font-sora text-[10px] mt-1 font-normal leading-[12.6px] text-center text-[#FFFFFFBF]">
                      Resend E-Mail Code{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-2 justify-center items-center mt-6">
              <div className={`w-1 h-1 rounded-full bg-[#6d6d6d]`}></div>
              <div className={`w-1 h-1 rounded-full bg-[#fff1f1b8]`}></div>
            </div>
            <div className="mx-[4%] justify-between flex gap-6 my-6">
              <button
                className="w-[40%] sm:w-[30%] border  border-[#FFFFFF80] py-2 rounded"
                onClick={() => {
                  onClose();
                  setOtp("");
                  setFaotp("");
                }}
              >
                <span className="font-sora text-[12px] font-bold  text-[#FFFFFF80]">
                  Back
                </span>
              </button>
              <button
                className="w-[40%] sm:w-[30%] border border-primary bg-primary py-2 rounded"
                onClick={() => {
                  handleConfirmation();
                }}
              >
                <span className=" font-sora text-[12px] font-bold text-black">
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-7 mx-auto h-7 animate-spin"
                    />
                  ) : (
                    "Confirm Address"
                  )}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default RemoveWhitelistAddress;
