import React from "react";
import Getstartedcard from "./Getstartedcard";

function Getstarted() {
  return (
    <div>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px]  -[4.8px] text-center"
      >
        HOW IT WORKS
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora  mt-3 px-3 font-semibold leading-[49.28px]  -[-0.88px] text-center text-[34px] sm:text-[50px]"
      >
        3 EASY STEPS TO GET YOU STARTED
      </p>
      <div className="max-w-[1340px] mx-auto">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
          className=" flex lg:flex-row flex-col mt-12  justify-center gap-8 items-center  "
        >
          <Getstartedcard
            heading="1. Create An Account"
            paragraph="Register and complete our secure verification process (KYC) to ensure compliance with financial regulations."
          />
          <Getstartedcard
            heading="2. Fund Your Account"
            paragraph="Make your initial deposit starting from 50€. Our Pro Plan (minimum 2.500€) unlocks optimal trading conditions*"
          />
          <Getstartedcard
            heading="3. Monitor Your Investment"
            paragraph="Once funded, our algorithms begin trading automatically. Access your dashboard to track your portfolio's performance in real-time."
          />
        </div>
        <p className="font-sora text-[14px] started:ml-[5vh] xl:ml-[10vh] 2xl:ml-[0vh] mx-2 text-[#9B9B9B80] mt-6 font-normal leading-[19.6px] text-left">
          *Note: Exchange minimum order sizes may limit trading capabilities for
          smaller deposits.
        </p>
      </div>
    </div>
  );
}

export default Getstarted;
