import React from "react";

function Getstartedcard(props) {
  return (
    <div className="bg-[#222222] transform transition-transform duration-300 hover:-translate-y-2  sm:mt-4 w-[90%] sm:w-96 2xl:w-[430px]  h-60 rounded-[24px]">
      <h1 className="font-sora mt-[12%] ml-6 text-[22.4px] font-semibold leading-[25.09px]  -[-0.48px] text-center">
        {props.heading}
      </h1>
      <p className="font-montserrat  hover:text-accent px-4 sm:px-12  mt-4 text-[#AAAAAA] text-[16px] font-normal leading-[24px] text-center">
        {props.paragraph}
      </p>
    </div>
  );
}

export default Getstartedcard;
