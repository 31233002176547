import React, { useState, useEffect, useRef, useContext } from "react";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import { QRCodeSVG } from "qrcode.react";
import { UserContext } from "../../contexts/UserContext";
import BigLoader from "../../components/BigLoader";

function Referral() {
  const [focusedItem, setFocusedItem] = useState("referral");
  const [copied, setCopied] = useState(false);
  const [copiedurl, setCopiedurl] = useState(false);
  const [referralURL, setReferralURL] = useState("");
  const { user } = useContext(UserContext);
  const qrRef = useRef(null); // Create a ref for the QR code
  const [mainLoader, setMainLoader] = useState(false);

  useEffect(() => {
    if (user?.referralCode) {
      const url = `https://algox.capital/signup/${user.referralCode}`;
      setReferralURL(url);
    }
    if (!user) {
      setMainLoader(true);
    } else {
      setMainLoader(false);
    }
  }, [user?.referralCode]);

  const handleFocus = (item) => {
    setFocusedItem(item);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`${user?.referralCode}`).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    });
  };
  const Copyurl = () => {
    navigator.clipboard.writeText(`${referralURL}`).then(() => {
      setCopiedurl(true);
      setTimeout(() => {
        setCopiedurl(false);
      }, 2000);
    });
  };

  const handleDownload = () => {
    const svgElement = qrRef.current.querySelector("svg"); // Get the SVG element
    const svgData = new XMLSerializer().serializeToString(svgElement); // Serialize the SVG
    const canvas = document.createElement("canvas"); // Create a canvas element
    const svgSize = svgElement.getBoundingClientRect(); // Get size of the SVG
    canvas.width = svgSize.width; // Set canvas width
    canvas.height = svgSize.height; // Set canvas height
    const ctx = canvas.getContext("2d"); // Get canvas context
    const img = new Image(); // Create an image element

    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {
      ctx.drawImage(img, 0, 0); // Draw the image on the canvas
      URL.revokeObjectURL(url); // Revoke the object URL
      const pngFile = canvas.toDataURL("image/png"); // Convert the canvas to PNG
      const link = document.createElement("a");
      link.href = pngFile;
      link.download = "QRCode.png"; // Set the download file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    img.src = url; // Set the image source to the blob URL
  };

  return (
    <div className="newbody">
      <div>
        <div className="newsignup-div px-4">
          <Navbar />
          <p className="font-sora text-primary text-base font-normal mb-3 mt-16 leading-[22.4px]  -[0.3em] text-center">
            Account
          </p>
          <p className="font-sora text-[44px] font-semibold pb-12 leading-[49.28px]  -[-0.02em] text-center">
            Referral Program
          </p>

          {mainLoader ? ( // Render loader if loading
            <div
              className="flex justify-center h-full items-center"
              style={{ height: "50vh" }}
            >
              <BigLoader />
            </div>
          ) : (
            <div className="grid grid-cols-1 lg:grid-cols-5 pt-16 pb-96 px-[5%]">
              {/* <div className="col-span-1 hidden md:block">
              <div className="grid bg-[#222222] w-56 px-3 pt-6 pb-24 rounded-3xl ml-auto mt-20">
                <Link to="/usersetting">
                  <div
                    className={`flex gap-2 cursor-pointer py-3 px-2 rounded-sm ${
                      focusedItem === "personal"
                        ? "text-primary"
                        : "text-accent"
                    }`}
                    tabIndex={0}
                    onFocus={() => handleFocus("personal")}
                  >
                    <img
                      src={`/icon/${
                        focusedItem === "personal"
                          ? "vector1.svg"
                          : "vector2.svg"
                      }`}
                      alt="vector icon"
                    />
                    <p
                      className={`font-inter text-[10px] lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                        focusedItem === "personal" ? "text-primary" : ""
                      }`}
                    >
                      Personal Settings
                    </p>
                    <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "downarrow1.svg"
                          : "downarrow2.svg"
                      }`}
                      alt="arrow icon"
                    />
                  </div>
                </Link>
                <Link to="/referral">
                  <div
                    className={`flex gap-2 cursor-pointer py-3 px-2 rounded-sm ${
                      focusedItem === "referral"
                        ? "text-primary"
                        : "text-accent"
                    }`}
                    tabIndex={0}
                    onFocus={() => handleFocus("referral")}
                  >
                    <img
                      src={`/icon/${
                        focusedItem === "referral"
                          ? "vector2.svg"
                          : "vector1.svg"
                      }`}
                      alt="vector icon"
                    />
                    <p
                      className={`font-inter text-[10px] lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                        focusedItem === "referral" ? "text-primary" : ""
                      }`}
                    >
                      Referral Program
                    </p>
                  </div>
                </Link>
                <Link to="/notificationsetting">
                  <div
                    className={`flex gap-2 cursor-pointer py-3 px-2 rounded-sm ${
                      focusedItem === "notification"
                        ? "text-primary"
                        : "text-accent"
                    }`}
                    tabIndex={0}
                    onFocus={() => handleFocus("notification")}
                  >
                    <img
                      src={`/icon/${
                        focusedItem === "notification"
                          ? "vector1.svg"
                          : "vector2.svg"
                      }`}
                      alt="vector icon"
                    />
                    <p
                      className={`font-inter text-[10px] lg:text-[14px] mr-4 font-medium leading-[16.94px] text-left ${
                        focusedItem === "notification" ? "text-primary" : ""
                      }`}
                    >
                      Notification Centre
                    </p>
                  </div>
                </Link>
              </div>
            </div> */}

              <div className="col-span-2">
                <div ref={qrRef}>
                  {" "}
                  {/* Attach ref here */}
                  <QRCodeSVG
                    value={referralURL} // The URL the QR code will link to
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="Q"
                    includeMargin={true}
                    className="size-[70%] mx-auto"
                  />
                </div>
                {/* <div className="w-[60%] sm:w-[45%] mx-auto bg-[#222222] rounded-md mt-8 pb-4 relative">
                <p className="font-roboto text-[15.2px] font-light leading-[32.94px] py-2 text-center">
                  Referral Code : {user?.referralCode}
                </p>
                <div
                  className="bg-primary cursor-pointer w-[70%] mx-auto rounded-md py-1 sm:py-2"
                  onClick={handleCopy}
                >
                  <p className="font-roboto text-[13px] sm:text-[19px] font-medium leading-[32.94px] text-center">
                    Copy Code
                  </p>
                </div>
                {copied && (
                  <div className="absolute top-[-25px] left-1/2 transform -translate-x-1/2 bg-black text-accent text-sm rounded-md px-2 py-1">
                    Copied!
                  </div>
                )}
                <div
                  className="bg-primary mt-4 cursor-pointer w-[70%] mx-auto rounded-md py-1 sm:py-2"
                  onClick={handleDownload}
                >
                  <p className="font-roboto text-[13px] sm:text-[19px] font-medium leading-[32.94px] text-center">
                    Download QR code
                  </p>
                </div>
              </div> */}
              </div>

              <div className="col-span-3 relative">
                <div className="bg-[#1F1B2C] py-6 px-6 mt-16 lg:mt-0  mb-4 rounded-3xl">
                  <div className="flex justify-between">
                    <div className="gap-6 flex flex-col sm:flex-row">
                      <img
                        src="/icon/referrallink.png"
                        className=" size-10 sm:size-20 rounded-xl"
                        alt="referrallink"
                      />
                      <div>
                        <p className="font-sora text-[16px] sm:text-[24px] font-semibold leading-[26.88px] mb-2  -[-0.02em] text-left">
                          Copy Referral Link
                        </p>
                        <p className="font-sora text-[10px] sm:text-[16px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                          With this link, new members can directly register and{" "}
                          will be considered as your personal referral
                        </p>
                      </div>
                    </div>
                    <div className="">
                      <button
                        onClick={Copyurl}
                        className="bg-primary   mr-1 sm:mr-4 mt-7 sm:mt-3 px-6 h-8 sm:px-14 sm:h-12  rounded-xl hover:bg-[#f1ae1c] hover:text-accent"
                      >
                        <p className="font-sora text-[10px] sm:text-[16px] text-black text-center font-medium leading-[23.94px]  hover:text-accent">
                          Copy
                        </p>
                      </button>
                      {copiedurl && (
                        <div className="absolute top-[-70px] text-center min-w-64 left-1/2 transform -translate-x-1/2 bg-[#1F1B2C] text-accent text-md rounded-md px-8 py-4">
                          Referral Link Copied
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bg-[#1F1B2C] py-6 px-6 mb-4 rounded-3xl">
                  <div className="flex justify-between">
                    <div className="gap-6 flex flex-col sm:flex-row">
                      <img
                        src="/icon/referralcode.png"
                        className=" size-10 sm:size-20 rounded-xl"
                        alt="referralcode"
                      />
                      <div>
                        <p className="font-sora text-[16px] sm:text-[24px] font-semibold leading-[26.88px] mb-2  -[-0.02em] text-left">
                          Copy Referral Code
                        </p>
                        <p className="font-sora text-[10px] sm:text-[16px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                          With this code, a customer can register as a referral{" "}
                          during the sign up process
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={handleCopy}
                      className="bg-primary   mr-1 sm:mr-4 mt-7 sm:mt-3 px-6 h-8 sm:px-14 sm:h-12  rounded-xl hover:bg-[#f1ae1c] hover:text-accent"
                    >
                      <p className="font-sora text-[10px] sm:text-[16px] text-black text-center font-medium leading-[23.94px]  hover:text-accent">
                        Copy
                      </p>
                    </button>
                    {copied && (
                      <div className="absolute top-[-70px] text-center min-w-64 left-1/2 transform -translate-x-1/2 bg-[#1F1B2C] text-accent text-md rounded-md px-8 py-4">
                        Referral Code Copied
                      </div>
                    )}
                  </div>
                </div>
                <div className="bg-[#1F1B2C] py-6 px-6 rounded-3xl">
                  <div className="flex justify-between">
                    <div className="gap-6 flex flex-col sm:flex-row">
                      <img
                        src="/icon/qrcode.png"
                        className=" size-10 sm:size-20 rounded-xl"
                        alt="qrcode"
                      />
                      <div>
                        <p className="font-sora text-[16px] sm:text-[24px] font-semibold leading-[26.88px] mb-2  -[-0.02em] text-left">
                          Download QR Code
                        </p>
                        <p className="font-sora text-[10px] sm:text-[16px] font-normal leading-[24px] text-left text-[#9B9B9B]">
                          With this QR code a customer receives your personal
                          referral link and will automatically be considered as
                          your personal referral
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={handleDownload}
                      className="bg-primary   mr-1 sm:mr-4 mt-7 sm:mt-3 px-3 h-8 sm:px-9 sm:h-12  rounded-xl hover:bg-[#f1ae1c] hover:text-accent"
                    >
                      <p className="font-sora text-[10px] sm:text-[16px] text-black text-center font-medium leading-[23.94px]  hover:text-accent">
                        Download
                      </p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Referral;
