import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL } from "../constants/constant";
import Notification from "./Notification";
import { postRequest } from "../backendServices/ApiCalls";

function ForgetPasswordEmail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  // const handleForgetPassword = async () => {
  //   if (!email) {
  //     Notification.showNotification("error", "Error", "Please enter your email.");
  //     return;
  //   }

  //   if (!navigator.onLine) {
  //     Notification.showNotification(
  //       "error",
  //       "Network Error",
  //       "Please check your internet connection and try again."
  //     );
  //     return;
  //   }

  //   setLoading(true);
  //   try {
  //     const response = await fetch(`${URL}/auth/forgetpassword`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ email }),
  //     });

  //     const data = await response.json();

  //     if (response.status === 200) {
  //       Notification.showNotification(
  //         "success",
  //         "Success",
  //         "Password reset email sent!"
  //       );
  //       // setTimeout(() => {
  //       //   navigate(`/resetpassword/${data?.resetToken}/${email}`); // Navigate to the homepage
  //       // }, 1000);
  //     } else {
  //       Notification.showNotification("error", "Error", data.message);
  //     }
  //   } catch (error) {
  //     Notification.showNotification("error", "Error", "Error");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const handleForgetPassword = () => {
    if (!email) {
      Notification.showNotification(
        "error",
        "Error",
        "Please enter your email."
      );
      return;
    }

    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }

    setLoading(true);
    postRequest(
      "no",
      "auth/forgetpassword",
      { email },
      (response) => {
        const data = response?.data;
        if (response.status === 200) {
          Notification.showNotification(
            "success",
            "Success",
            "Password reset email sent!"
          );
          setTimeout(() => {
            navigate(`/resetpassword/${data?.resetToken}/${email}`);
          }, 1000);
        } else {
          Notification.showNotification("error", "Error", data.message);
        }
        setLoading(false);
      },
      (error) => {
        Notification.showNotification(
          "error",
          "Error",
          error.response?.data?.message || error.message
        );
        console.error("An error occurred:", error);
        setLoading(false);
      }
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleForgetPassword();
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="mx-6">
      <h1 className="text-accent  -tighter mt-16 mb-0 text-center text-6xl font-bold leading-normal">
        Forget Password
      </h1>
      <div className="max-w-[488px] mt-6 pt-6 h-auto pb-6 bg-[#222222] mx-auto rounded-3xl">
        <div className="w-16 h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
          <img
            src="/icon/yellowProfile.png"
            className="w-6 h-6"
            alt="Profile"
          />
        </div>
        <div className="mx-8 mt-4">
          <p className="text-accent  -tighter mb-0 text-lg font-semibold leading-relaxed block">
            Email
          </p>
          <input
            type="email"
            value={email}
            name="email"
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
          />
        </div>
        <button
          onClick={handleForgetPassword}
          disabled={loading}
          className={`bg-primary w-[40%] sm:w-[87%] mt-10 text-black font-medium rounded-xl text-md py-2 mx-auto sm:py-4 sm:mx-8 flex items-center justify-center ${
            loading ? "cursor-not-allowed opacity-50" : ""
          }`}
        >
          {loading ? (
            <img
              src="/icon/loader.svg"
              alt="Loading..."
              className="w-7 mx-auto h-7 animate-spin"
            />
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
}

export default ForgetPasswordEmail;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { URL } from "../constants/constant";
// import Notification from "./Notification";

// function ForgetPasswordEmail() {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [email, setEmail] = useState(false);

//   const handleForgetPassword = async () => {
//     if (!navigator.onLine) {
//       Notification.showNotification(
//         "error",
//         "Network Error",
//         "Please check your internet connection and try again."
//       );
//       return;
//     }
//     setLoading(true);
//     try {
//       const response = await fetch(`${URL}/auth/login`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: "",
//       });
//       const data = await response.json();
//       // console.log("🚀 ~ handleSignin ~ data:", data);

//       if (response.status === 200) {
//         // console.log(data);
//         Notification.showNotification(
//           "success",
//           "Success",
//           "User Successfully Login"
//         );
//         setTimeout(() => {
//           navigate("/");
//         }, 1000);
//       } else {
//         Notification.showNotification("error", "Error", data.message);
//         // console.log(data.error);
//       }
//     } catch (error) {
//       Notification.showNotification("error", "Error", "Error");
//       setLoading(false);
//       // console.error("An error occurred:", error);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       handleSignin();
//     }
//   };

//   return (
//     <div className="mx-6">
//       <h1 className="text-accent  -tighter mt-16 mb-0 text-center text-6xl font-bold leading-normal">
//         Forget Password
//       </h1>
//       <div className="max-w-[488px] mt-6 pt-6 h-auto pb-6 bg-[#222222] mx-auto rounded-3xl">
//         <div className="w-16 h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
//           <img
//             src="/icon/yellowProfile.png"
//             className="w-6 h-6"
//             alt="Profile"
//           />
//         </div>
//         <div className="mx-8 mt-4">
//           <p className="text-accent  -tighter mb-0 text-lg font-semibold leading-relaxed block">
//             Email
//           </p>
//           <input
//             type="email"
//             value={email}
//             name="email"
//             onChange={handleChange}
//             onKeyPress={handleKeyPress}
//             className="border mt-1 px-2 h-12 rounded-xl border-[#383838] w-full bg-transparent text-accent"
//           />
//         </div>
//         <button
//           onClick={handleForgetPassword}
//           disabled={loading}
//           className={`bg-primary w-[40%] sm:w-[87%] mt-10 text-black font-medium rounded-xl text-md py-2 mx-auto sm:py-4 sm:mx-8 flex items-center justify-center ${
//             loading ? "cursor-not-allowed opacity-50" : ""
//           }`}
//         >
//           {" "}
//           {loading ? (
//             <img
//               src="/icon/loader.svg"
//               alt="Loading..."
//               className="w-7 mx-auto h-7 animate-spin"
//             />
//           ) : (
//             "Submit"
//           )}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default ForgetPasswordEmail;
