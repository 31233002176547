import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./main/Home";
import Signup from "./main/Signup";
import Login from "./main/Login";
import JoinWaitlist from "./main/JoinWaitlist";
import UserSetting from "./main/UserSetting";
import Referral from "./main/Referral";
import NotificationSetting from "./main/NotificationSetting";
import SecurityVerification from "./main/SecurityVerification";
import Notification from "./main/Notification";
import PrivateRoute from "./PrivateRoute";
import PrivacyPolicy from "./main/PrivacyPolicy";
import CookiesPage from "./main/CookiesPage";
import DisclaimerPage from "./main/DisclaimerPage";
import Impirint from "./main/Impirint";
import Guideline from "./main/Guideline";
import TermsandConditions from "./main/TermsAndConditions";
import PricingPlane from "./main/PricingPlane";
import Purchase from "./main/Purchase";
import Dashboard from "./main/Dashboard";
import VerifyKyc from "./main/VerifyKyc";
import UserTransaction from "./main/UserTransaction";
import ForgetPasswordEmailPage from "./main/ForgetPasswordEmailPage";
import ResetPassword from "./main/ResetPassword";
import AdminPanal from "./main/AdminPanal";
import Activity from "./main/Activity";
import FaqsModal from "./main/FaqsModal";
import ContactUs from "./main/ContactUs";

export default function Index() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signup/:referralCode?" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/joinwaitlist" element={<JoinWaitlist />} />
        <Route path="/forgetpasswordemail" element={<ForgetPasswordEmailPage />} />
        {/* /reset-password?token=${resetToken}&email=${encodeURIComponent(email)}` */}
        <Route path="/resetpassword/:resetCode/:email" element={<ResetPassword />} />
        <Route
          path="/securityverification"
          element={<SecurityVerification />}
        />
        <Route path="/notification" element={<Notification />} />
        <Route
          path="/notificationsetting"
          element={<PrivateRoute Component={NotificationSetting} />}
        />
        <Route
          path="/referral"
          element={<PrivateRoute Component={Referral} />}
        />
        <Route
          path="/usersetting"
          element={<PrivateRoute Component={UserSetting} />}
        />
        <Route
          path="/purchase"
          element={<PrivateRoute Component={Purchase} />}
        />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<DisclaimerPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route path="/imprint" element={<Impirint />} />
        <Route path="/faqs" element={<FaqsModal />} />
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="/guideline" element={<Guideline />} />
        <Route path="/termsandconditions" element={<TermsandConditions />} />
        {/* <Route path="/verify/:token" element={<VerifyKyc />} /> */}
        <Route
          path="/pricingplan"
          element={<PrivateRoute Component={PricingPlane} />}
        />
        <Route
          path="/activity"
          element={<PrivateRoute Component={Activity} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute Component={Dashboard} />}
        />
        <Route
          path="/contactus"
          element={<PrivateRoute Component={ContactUs} />}
        />
        <Route
          path="/user-transaction"
          element={<PrivateRoute Component={UserTransaction} />}
        />
        <Route
          path="/adminpanal"
          element={<PrivateRoute Component={AdminPanal} />}
        />
      </Routes>
    </>
  );
}
