
import React, { useRef, useEffect } from "react";

function AccountFaqs({
  question = "",
  listItems = [],
  paragraph = "",
  isExpanded,
  onToggle,
}) {
  const contentRef = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0px";
    }
  }, [isExpanded]);

  return (
    <div className="flex flex-col items-center sm:mb-6 lg:mb-0 justify-center">
      <div
        className={`w-[85%] pr-1 sm:w-[50%] mx-auto border-secondary h-auto 3xl:mx-[350px] 4xl:mx-[490px] flex flex-col justify-between mt-4 sm:mt-5 sm:mx-16 md:mx-24 lg:mx-32 rounded-3xl border cursor-pointer`}
        style={{ maxWidth: "calc(100% - 40px)" }}
        onClick={onToggle}
      >
        <div className={`flex items-center`}>
          <img
            src={`${isExpanded ? "/icon/minus.webp" : "/icon/plus.svg"}`}
            className={`rounded-full ml-5 w-6 ${isExpanded ? "bg-purple h-6 my-auto" : ""}`}
            alt="plus icon"
          />
          <h1 className="font-sora text-[22.4px] font-semibold leading-[25.09px]  text-left py-6 ml-3">
            {question}
          </h1>
        </div>
        <div
          ref={contentRef}
          className={`expandable ${isExpanded ? "visible-content" : "hidden-content"}`}
          style={{
            maxHeight: "0px",
            overflow: "hidden",
            transition: "max-height 0.7s ease-out",
          }}
        >
          <div className="expandable-content text-[#AAAAAA] pb-6 px-12">
            {listItems.length > 0 && (
              <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                {listItems.map((item, index) => (
                  <li key={index} style={{ paddingLeft: "5px" }}>
                    {item}
                  </li>
                ))}
              </ul>
            )}
            <br />
            {paragraph}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AccountFaqs;
