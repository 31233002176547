import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Chart from "react-apexcharts";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getUserTransactionsList,
  postRequest,
} from "../../backendServices/ApiCalls";
import { UserContext } from "../../contexts/UserContext";
import Loader from "../../components/Loader";
import { Dropdown, Space } from "antd";

function UserTransaction() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [transactionData, setTransactionData] = useState(null);
  const [tableLoader, setTableLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const location = useLocation();
  const [expireloadingkyc, setExpireLoadingkyc] = useState(false);
  const [loadingkyc, setLoadingkyc] = useState(false);

  const getTransactionList = (userId) => {
    if (!userId) {
      return;
    }

    setTableLoader(true);
    getUserTransactionsList(
      userId,
      (response) => {
        setTransactionData(response?.data);
        setFilteredData(response?.data);
        setTableLoader(false);
      },
      (error) => {
        setTableLoader(false);
      }
    );
  };

  useEffect(() => {
    if (!query) {
      setFilteredData(transactionData);
    } else {
      const filtered = transactionData?.filter((item) => {
        return (
          item.orderId.toLowerCase().includes(query.toLowerCase()) ||
          item.paymentId.toLowerCase().includes(query.toLowerCase()) ||
          item.systemStatus.toLowerCase().includes(query.toLowerCase()) ||
          item.paymentcurrency.toLowerCase().includes(query.toLowerCase()) ||
          (item.paidAmountUSD &&
            item.paidAmountUSD.toString().includes(query)) ||
          item.package.name.toLowerCase().includes(query.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }
  }, [query, transactionData]);

  useEffect(() => {
    if (user && user._id) {
      getTransactionList(user._id);
    }
  }, [user]);

  const payRemaining = (url) => {
    window.open(url, "_blank");
  };
  const handleVerify = async () => {
    setLoadingkyc(true);
    const headers = {};
    postRequest(
      "yes",
      "auth/userAccessCode",
      {},
      (data) => {
        if (data?.data?.success) {
          window.location.href = `https://verify.authenticating.com/?token=${data?.data?.kyctoken}`;
        } else {
          console.error("Failed to store data in backend:", data);
          Notification.showNotification(
            "error",
            "Verification Error",
            data.message || "Could not verify user."
          );
        }
        setLoadingkyc(false);
      },
      (error) => {
        console.error("An error occurred during verification:", error);
        Notification.showNotification(
          "error",
          "Verification Error",
          "Could not verify user."
        );
        setLoadingkyc(false);
      },
      headers
    );
  };
  const expirecheck = async () => {
    setExpireLoadingkyc(true);
    try {
      const currentTime = new Date();
      const expirationTime = new Date(user?.expiresOn);
      console.log("current", currentTime, "expire", expirationTime);
      if (currentTime < expirationTime) {
        window.location.href = `https://verify.authenticating.com/?token=${user.kyctoken}`;
      } else {
        postRequest(
          "yes",
          "auth/recreatetoken",
          {},
          (data) => {
            window.location.href = `https://verify.authenticating.com/?token=${data?.data?.kyctoken}`;
          },
          (error) => {
            console.error("Failed to store data in backend:", error);
            Notification.showNotification(
              "error",
              "Verification Error",
              error.message || "Could not verify user."
            );
          },
          { "Content-Type": "application/json" }
        );
      }
    } catch (error) {
      console.error("An error occurred during token expiration check:", error);
    } finally {
      setExpireLoadingkyc(false);
    }
  };
  const [selectedItem, setSelectedItem] = useState("");

  const handleSelect = (item) => {
    setSelectedItem(item);
  };
  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
  };
  const items = [
    {
      key: "1",
      label: (
        <Link to="/usersetting" className="text-accent hover:text-primary">
          Account settings
        </Link>
      ),
    },
    {
      key: "2",
      label: (
        <Link className="text-accent hover:text-primary" onClick={logout}>
          Logout
        </Link>
      ),
    },
  ];
  return (
    <div className="body">
      <div className="navbar-bg-color min-h-[100vh] px-2 lg:px-4">
        {/* <Navbar /> */}
        <div className="grid grid-cols-5 items-center mx-[3%]">
          <div className="hidden sm:block">
            <Link to="/">
              <img
                src="/icon/logo.svg"
                className="ml-1 my-auto w-40 lg:w-60 md:w-72 mt-[5%] cursor-pointer"
                alt="Logo"
              />
            </Link>
          </div>
          <div className="flex justify-between mt-6 col-span-5 sm:col-span-4 items-center ml-4 w-full">
            <div>
              <div className="sm:hidden">
                <Link to="/">
                  <img
                    src="/icon/logo.svg"
                    className="ml-1 my-auto w-48  cursor-pointer"
                    alt="Logo"
                  />
                </Link>
              </div>
              <div className="flex flex-col sm:flex-row gap-9 ">
                <p className="font-sora my-auto  text-md lg:text-[26px] font-extrabold leading-[62.72px]  -[-1.68px] text-left text-accent">
                  Welcome {user?.name}
                  <span className="font-sora text-[14px] font-bold leading-[23.94px] ml-2 text-center text-primary px-2 py-1 rounded-2xl bg-[#FFBF0026]">
                    Beta
                  </span>
                </p>
                {user?.kycStatus === "success" ? (
                  ""
                ) : (
                  <div
                    className="my-auto flex gap-2 border cursor-pointer border-primary pt-1 h-9 px-4 rounded"
                    onClick={() => {
                      if (!user?.kyctoken) {
                        handleVerify();
                      } else if (
                        user?.kyctoken &&
                        user?.kycStatus !== "success"
                      ) {
                        expirecheck();
                      } else {
                        return;
                      }
                    }}
                  >
                    {!loadingkyc ? (
                      <>
                        <p className="font-sora mt-0.5 text-[12px] font-bold leading-[23.94px] text-primary text-center">
                          {user?.kycStatus === "success" && "Verified"}
                          {!user?.kyctoken && "Verrify Account"}
                          {user?.kyctoken && (
                            <p className="font-sora text-xs font-bold leading-6 text-center text-primary">
                              {expireloadingkyc ? (
                                <img
                                  src="/icon/loaderwhite.svg"
                                  alt="Loading..."
                                  className="w-7 ml-[10%] h-7 animate-spin"
                                />
                              ) : (
                                `${
                                  user?.kycStatus === "success"
                                    ? ""
                                    : "Verrify Account"
                                }`
                              )}
                            </p>
                          )}
                        </p>
                        <img
                          src="/icon/vaarrow.png"
                          className="size-4 mt-1.5"
                        />{" "}
                      </>
                    ) : (
                      <img
                        src="/icon/loaderwhite.svg"
                        alt="Loading..."
                        className="w-7 ml-[5%] h-7 animate-spin"
                      />
                    )}
                  </div>
                )}
              </div>
              {user?.kycStatus === "success" ? (
                <div className="flex gap-2 mb-4 mt-[-5%]">
                  <img src="./icon/vtick.svg" className="w-6 h-3 my-auto " />
                  <p className="font-sora text-xs font-bold leading-[23.94px] text-primary text-center text- my-auto">
                    verified
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
            <Space direction="vertical">
              <Space wrap>
                <Dropdown
                  overlayClassName="custom-dropdown-menu"
                  menu={{
                    items,
                  }}
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <div className="flex gap-5 items-center bg-[#191919] px-4 py-2 rounded-3xl">
                    <img
                      src="/icon/userprofile.png"
                      className="cursor-pointer h-6 w-6"
                      alt="User Profile"
                    />
                    <img
                      src="/icon/userprofiledownarrow.png"
                      className="cursor-pointer h-2 w-3"
                      alt="Dropdown Arrow"
                    />
                  </div>
                </Dropdown>
              </Space>
            </Space>
            {/* <div
                className="flex gap-3 bg-black w-[64%] px-4 py-1 rounded cursor-pointer mt-[-12%] mb-6"
                onClick={openModalpro}
              >
                <p className="font-sora text-[12px] font-bold leading-[23.94px] text-center text-primary">
                  Upgrade to Pro
                </p>
                <img src="/icon/upgradepro.svg" className=" h-4 w-3 my-auto" />
              </div> */}
            {/* <div className="flex gap-5 items-center bg-[#191919] px-4 py-2 rounded-3xl">
              <img
                src="/icon/userprofile.png"
                className="cursor-pointer h-6 w-6"
                alt="User Profile"
              />
              <img
                src="/icon/userprofiledownarrow.png"
                className="cursor-pointer h-2 w-3"
                alt="Dropdown Arrow"
              />
            </div> */}
          </div>
        </div>

        <div className="grid sm:grid-cols-5 ">
          <div className="sm:col-span-1 hidden sm:block border-r border-[#404040]">
            <div
              onClick={() => navigate("/dashboard")}
              className={`flex gap-2 px-3 mt-2 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                location.pathname === "/dashboard"
                  ? "border-white border text-white"
                  : "border-[#404040] text-[#9B9B9B]"
              }`}
            >
              <img
                src={
                  location.pathname === "/dashboard"
                    ? "/icon/dashboard2.svg"
                    : "/icon/dashboard.svg"
                }
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="dashboardicon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                Dashboard
              </p>
            </div>
            <div
              onClick={() => navigate("/user-transaction")}
              className={`flex gap-2 px-3 mt-2 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                location.pathname === "/user-transaction"
                  ? "border-white border text-white"
                  : "border-[#404040] text-[#9B9B9B]"
              }`}
            >
              <img
                src={
                  location.pathname === "/user-transaction"
                    ? "/icon/Transaction2.svg"
                    : "/icon/Transaction.svg"
                }
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="transactionicon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                Transaction History
              </p>
            </div>

            <hr className="mx-2 lg:mx-8 my-4 border-t border-[#404040]" />
            <div
              onClick={() => navigate("/contactus")}
              className={`flex gap-2 px-3 mt-4 cursor-pointer py-2 mx-2 lg:mx-8 rounded-xl ${
                location.pathname === "/contact"
                  ? "border-white text-white"
                  : "border-[#404040] text-[#9B9B9B]"
              }`}
            >
              <img
                src={
                  location.pathname === "/contact"
                    ? "/icon/contact.svg"
                    : "/icon/contact.png"
                }
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="contacticon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] font-normal leading-[24px] text-left mt-0.5">
                Contact Us
              </p>
            </div>
          </div>

          <div className="col-span-4 my-4 grid grid-cols-1 lg:grid-cols-0 px-[4%]">
            <div className="col-span-1 lg:col-span-1 border border-[#404040] py-4 px-[4%] pt-6 rounded-3xl">
              <p className="font-sora text-[16px] font-bold leading-[20.16px]  -tight text-left text-[#FFFFFFBF]">
                User Transaction History
              </p>

              <div className="flex items-center justify-between mt-5">
                <div className="flex items-center gap-3">
                  <form>
                    <label
                      for="default-search"
                      class="mb-2 text-sm font-medium text-gray-900 sr-only"
                    >
                      Search...
                    </label>
                    <div class="relative">
                      <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg
                          class="w-4 h-4 text-gray-500"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                          />
                        </svg>
                      </div>
                      <input
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        type="search"
                        id="default-search"
                        class="block w-full p-2 bg-transparent ps-10 text-sm text-white border border-gray-300 rounded-full focus:ring-[#FFFFFF80] focus:border-[#FFFFFF80]"
                        placeholder="Search..."
                        required
                      />
                    </div>
                  </form>

                  <div className="bg-[#40404040] p-2 rounded-full">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3333 2H2.66659C1.92659 2 1.33325 2.59333 1.33325 3.33333V11.3333C1.33325 12.0667 1.92659 12.6667 2.66659 12.6667H5.33325V14H10.6666V12.6667H13.3333C14.0666 12.6667 14.6666 12.0667 14.6666 11.3333V3.33333C14.6666 2.59333 14.0666 2 13.3333 2ZM13.3333 11.3333H2.66659V3.33333H13.3333V11.3333Z"
                        fill="white"
                      />
                      <path
                        d="M9.33325 5.5H3.99992V6.5H9.33325V5.5Z"
                        fill="white"
                      />
                      <path
                        d="M10.9999 6.5H11.9999V5.5H10.9999V4.66667H9.99992V7.33333H10.9999V6.5Z"
                        fill="white"
                      />
                      <path
                        d="M11.9999 8.16667H6.66659V9.16667H11.9999V8.16667Z"
                        fill="white"
                      />
                      <path
                        d="M4.99992 10H5.99992V7.33333H4.99992V8.16667H3.99992V9.16667H4.99992V10Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
                <div>
                  <div className="bg-[#40404040] p-2 rounded-full">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 12H14V10.6667H2V12ZM2 8.66667H14V7.33333H2V8.66667ZM2 4V5.33333H14V4H2Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {tableLoader ? (
                <div className="flex justify-center h-full items-center">
                  <Loader />
                </div>
              ) : (
                <div className="overflow-auto mt-4 min-h-0">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="border-b border-[#404040]">
                        {[
                          "Package Name",
                          "Amount Paid ",
                          "Order ID",
                          "Time of Purchase",
                        ].map((header) => (
                          <th
                            key={header}
                            className="px-2 py-2 text-[10px] md:text-[14px] font-normal leading-[24px] text-left text-[#9B9B9B]"
                          >
                            {header}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody
                      className={
                        tableLoader ? "flex justify-center items-center" : ""
                      }
                    >
                      {filteredData?.map((value, index) => (
                        <tr key={index}>
                          <td className="px-3 py-3">
                            <div className="flex gap-2">
                              <img
                                src="/icon/dashboardgallery.png"
                                className="w-3 sm:w-6 my-auto h-2 sm:h-4"
                                alt="icon"
                              />
                              <p className="text-[10px] lg:text-[16px] text-white">
                                {value?.package?.name}
                              </p>
                            </div>
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {value?.package?.amount}
                          </td>
                          <td className="px-3 py-3 text-white">
                            {String(value?.paidAmount).slice(0, 7)}
                          </td>
                          <td className="px-3 py-3 text-white">
                            {value?.paymentcurrency}
                          </td>
                          <td className="px-3 py-3 text-white">
                            {value?.paidAmountUSD}
                          </td>
                          <td
                            className={`${
                              value?.systemStatus === "Done"
                                ? "text-[#3BD306] font-semibold"
                                : value?.systemStatus === "Pending"
                                ? "text-[#FFA500]"
                                : ""
                            }`}
                          >
                            {value?.systemStatus}
                          </td>
                          <td
                            className={`${
                              value?.chargeStatus === "Done"
                                ? "text-[#3BD306] font-semibold"
                                : value?.chargeStatus === "Partial"
                                ? "text-[#FFA500]"
                                : ""
                            }`}
                          >
                            {value?.chargeStatus}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {String(value?.payExtra).slice(0, 7)}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {value?.typeTransaction}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {value?.senderId}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {value?.receiverId}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {value?.paymentId}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {value?.orderId}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {value?.chain}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {new Date(value?.createdAt).toLocaleDateString()}
                          </td>
                          <td className="px-3 py-3 text-center text-white">
                            {new Date(value?.updatedAt).toLocaleDateString()}
                          </td>
                          <td className="px-3 py-3 text-center">
                            {value.systemStatus === "Done" &&
                              value.chargeStatus === "Partial" && (
                                // <a
                                //     href={`https://checkouts.nfg-crypto.io/sell-product/${value.paymentId}?clickId=${value.senderId}`}
                                //     target="_blank"
                                //     rel="noopener noreferrer"
                                //     className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                // >
                                //     Pay Remaining
                                // </a>
                                <button
                                  onClick={() =>
                                    payRemaining(
                                      `https://checkouts.nfg-crypto.io/sell-product/${value.paymentId}?clickId=${value.senderId}`
                                    )
                                  }
                                  className="bg-primary  hidden sm:block  mt-7 sm:mt-3 min-w-28 px-2 h-6 sm:px-2 sm:h-10 rounded-xl hover:bg-[#f1ae1c] hover:text-white"
                                >
                                  <p className="font-sora text-[8px] sm:text-[12px] text-black text-center font-medium leading-[23.94px]  hover:text-white">
                                    Pay Remaining
                                  </p>
                                </button>
                              )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>

        <div className="grid sm:grid-cols-5 pb-14">
          <div className=" sm:col-span-1 hidden sm:block  border-r border-[#404040]">
            <div className="flex gap-2 px-3 borde py-2 mt-[100%] cursor-pointer border-white mx-8  rounded-xl">
              {/* <img
                src="/icon/dashboardsetting.png"
                className="size-4 my-auto lg:size-6 cursor-pointer"
                alt="dashboardicon"
              />
              <p className="font-sans text-[12px] lg:text-[16px] text-[#9B9B9B] font-normal leading-[24px] text-left mt-0.5">
                Settings
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserTransaction;
