import React, { useState, useEffect} from "react";

import Elevate from "../../components/Elevate";
import Faqs from "../../components/Faqs";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar"
import AOS from 'aos';
import 'aos/dist/aos.css';
import LoginCard from "../../components/Logincard";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [rotateX, setRotateX] = useState(17.2483);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollValue = 0.3;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollDiff = scrollTop - lastScrollTop;

      if (scrollDiff > 0) {
        if (rotateX > 0) {
          setRotateX(Math.max(0, rotateX - scrollValue));
        }
      } else {
        if (rotateX < 30.2483) {
          setRotateX(Math.min(26.2483, rotateX + scrollValue));
        }
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, rotateX]);
  useEffect(() => {
     localStorage.getItem("token")
   if (localStorage.getItem("token")) {
    // navigate("/")
   }
  }, [])
  
  return (
    <div className="body">
      <div className="signup-div">
        <Navbar/>
        <LoginCard/>
       </div>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] text-primary mt-32 font-normal leading-[22.4px]  -[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[44px] mt-3 font-semibold leading-[49.28px]  -[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="400" className="mt-4 flex flex-col gap-6">
      <Faqs
          question="How do I get started with Algo X Capital?"
          paragraphfirst="Getting started with Algo X Capital is quick and easy:"
          listItems={[
            "Create an account: Sign up for free or choose a paid plan for extra features.",
            "Fund your account: Start with as little as $1. Most of our successful investors begin with around $2,000.",
            "Relax and earn: Our AI-powered algorithms trade 24/7, generating passive income for you.",
          ]}
          paragraph="That's it! Just sign up, fund your account, and let our advanced technology work for you."
          isExpanded={expandedIndex === 0}
          onToggle={() => handleToggle(0)}
        />{" "}
        <Faqs
          question="How much money do I need to start with Algo X Capital?"
          paragraphfirst="You can start with as little as $1 on our Free plan. However, for optimal results and to fully leverage our trading algorithms, we recommend an initial investment of around $2,000. This amount allows you to experience the full potential of our platform and see significant returns. Remember, while you can start small, larger investments typically yield more noticeable results."
          isExpanded={expandedIndex === 1}
          onToggle={() => handleToggle(1)}
        />{" "}
        <Faqs
          question="Is my money safe using Algo X Capital?"
          paragraphfirst="Your security is our top priority. Here's how we protect your funds:"
          listItems={[
            "Secure Storage: Your funds remain safely held in your personal account with ByBit, one of the world's largest and most trusted exchanges.",
            "Limited Access: Algo X Capital operates via a read-only API, meaning we can execute trades but never withdraw or hold your funds directly.",
            "Regulatory Compliance: As a German-based company, Algo X Capital fully complies with all EU regulations. We go beyond legal requirements, providing additional company insights to ensure 100% transparency.",
            "Transparency: You maintain full visibility and control of your account at all times.",
          ]}
          paragraph="While we employ robust security measures, please remember that all financial products carry inherent risks. We advise investing only what you can afford to lose."
          isExpanded={expandedIndex === 2}
          onToggle={() => handleToggle(2)}
        />
        <Faqs
          question="I have a problem, how can I contact the Algo X Capital Team? "
          paragraphfirst="We're here to help! Here are the ways you can reach our support team:"
          listItems={[
            "FAQ Section: Before reaching out, check our comprehensive FAQ section. You might find an immediate answer to your question there.",
            <span>
              Email Support: For any questions or issues, please contact us at {" "}
              <a className="text-[#523eba]" href="support@algox.capital">
                 support@algox.capital 
              </a>
              . Our dedicated team aims to respond within 24 hours.
            </span>,
          ]}
          paragraph="We're committed to providing you with prompt and effective support to ensure your experience with Algo X Capital is smooth and satisfactory."
          isExpanded={expandedIndex === 3}
          onToggle={() => handleToggle(3)}
        />{" "}
      </div>
      <Elevate />
     
      <Footer />
    </div>
  );
}
export default Login;
