import React, { useEffect } from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import ElevateBusiness from "../../components/ElevateBusiness";
import AccountFaqs from "../../components/AccountFaqs";
function CookiesPage() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="account">
      <div className="bg-account">
        <Navbar />

        <div className=" mx-4 sm:mx-12 md:mx-20 mt-20 2xl:w-4/5 2xl:mx-auto">
          <h1 className="font-sora text-5xl font-bold leading-[62.72px]  -[-0.03em] text-center">
            Cookies Policy
          </h1>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-12">
            What is a cookie?
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-accent2 mt-4 text-left">
            A cookie is a small piece of data that a website stores on your
            device when you visit. It typically contains information about the
            website itself, a unique identifier that allows the site to
            recognize your web browser when you return, additional data that
            serves the cookie’s purpose, and the lifespan of the cookie itself.{" "}
            <br />
            Cookies are used to enable certain features (e.g. logging in), track
            site usage (e.g. analytics), store your user settings (e.g. time
            zone, notification preferences), and to personalize your content
            (e.g. advertising, language).
            <br />
            Cookies set by the website you are visiting are usually referred to
            as first-party cookies. They typically only track your activity on
            that particular site. Cookies set by other sites and companies (i.e.
            third parties) are called third-party cookies They can be used to
            track you on other websites that use the same third-party service.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-6">
            Essential cookies
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-accent2 mt-4 text-left">
            Essential cookies are crucial to your experience of a website,
            enabling core features like user logins, account management,
            shopping carts, and payment processing.We use essential cookies to
            enable certain functions on our website.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-6">
            Performance cookies
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-accent2 mt-4 text-left">
            Performance cookies track how you use a website during your visit.
            Typically, this information is anonymous and aggregated, with
            information tracked across all site users. They help companies
            understand visitor usage patterns, identify and diagnose problems or
            errors their users may encounter, and make better strategic
            decisions in improving their audience’s overall website experience.
            These cookies may be set by the website you’re visiting
            (first-party) or by third-party services. They do not collect
            personal information about you. We do not use this type of cookie on
            our site.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-6">
            Functionality cookies
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-accent2 mt-4 text-left">
            Functionality cookies are used to collect information about your
            device and any settings you may configure on the website you’re
            visiting (like language and time zone settings). With this
            information, websites can provide you with customized, enhanced, or
            optimized content and services. These cookies may be set by the
            website you’re visiting (first-party) or by third-party services. We
            do not use this type of cookie on our site.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-6">
            Targeting/advertising cookies
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-accent2 mt-4 text-left">
            Targeting/advertising cookies help determine what promotional
            content is most relevant and appropriate to you and your interests.
            Websites may use them to deliver targeted advertising or limit the
            number of times you see an advertisement. This helps companies
            improve the effectiveness of their campaigns and the quality of
            content presented to you. These cookies may be set by the website
            you’re visiting (first-party) or by third-party services.
            Targeting/advertising cookies set by third-parties may be used to
            track you on other websites that use the same third-party service.
            We do not use this type of cookie on our site.
          </p>
        </div>
      </div>
      {/* <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] md:hidden text-purple mt-64 sm:mt-[45%] 2xl:mt-96 font-normal leading-[22.4px]  -[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[44px] mt-3 md:hidden font-semibold leading-[49.28px]  -[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="lg:hidden"
      >
       <AccountFaqs question="What payment methods do you accept?" />
        <AccountFaqs question="Are there any hidden fees?" />
        <AccountFaqs question="Is there a free trial available?" />
        <AccountFaqs question="How do I get started?" />
        <AccountFaqs question="How do I access my account?" />
      </div> */}

      {/* <ElevateBusiness /> */}

      <Footer />
    </div>
  );
}
export default CookiesPage;
