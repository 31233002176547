import React, { useContext, useRef, useState } from "react";
import axios from "axios";
import { URL } from "../constants/constant";
import Notification from "./Notification";
import { UserContext } from "../contexts/UserContext";
import { postRequest } from "../backendServices/ApiCalls";

const VerifyEmail = ({ onClose }) => {
  const { user, setUser } = useContext(UserContext);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Clearer state naming
  const [loading, setLoading] = useState(false);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Focus next field
      if (index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (!otp[index]) {
        // Focus previous field if empty
        if (index > 0) {
          otpRefs.current[index - 1]?.focus();
        }
      } else {
        // Clear current field
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleOtpClick = (index) => {
    otpRefs.current[index]?.focus();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("Text").slice(0, 6);
    const otpArray = [...pastedData];

    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      otpArray.forEach((char, idx) => {
        updatedOtp[idx] = char;
      });
      return updatedOtp;
    });

    // Focus on the last field after pasting
    const lastInput = document.querySelector(`input[name="code-5"]`);
    if (lastInput) lastInput.focus();
  };

  const handleVerifyOtp = () => {
    setLoading(true);
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      return;
    }
    postRequest(
      "yes",
      "auth/verified-email",
      { emailVerifiedOtp: otp.join("") },
      (response) => {
        if (response.data.success) {
          Notification.showNotification(
            "success",
            "Success",
            "Your Email Verified"
          );
          setIsModalOpen(false);
          setUser((prevUser) => ({
            ...prevUser,
            varifiedemailotp: response.data.data,
          }));
          onClose();
          setLoading(false);
        } else {
          Notification.showNotification("error", "Error", "Invalid OTP");
          setLoading(false);
        }
      },
      (error) => {
        console.error("Error verifying OTP", error);
        Notification.showNotification("error", "Error", "Invalid OTP");
        setLoading(false);
      }
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleVerifyOtp();
    }
  };

  const Images = {
    close: "./icon/CDM.svg",
  };
  const maskedEmail = (() => {
    const email = user?.email || "No email available";
    const [localPart, domainPart] = email.split("@");
    const firstPart = localPart.slice(0, 3);
    const maskedLocal = `${firstPart}***`;
    const maskedDomain =
      domainPart?.length > 3 ? `${domainPart.slice(0, 3)}**` : domainPart;
    return `${maskedLocal}@${maskedDomain}`;
  })();
  return (
    <div className="fixed z-50 inset-0 mx-2 flex items-center justify-center backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
      <div className="bg-Accent p-4 rounded-3xl  animate-fadeIn bg-[#211E34E5] pt-6 border border-[#FFFFFF26]">
        <div className="relative flex justify-end items-center p-4">
          <img
            src={Images.close}
            width={20}
            height={20}
            onClick={onClose}
            className="absolute cursor-pointer"
            alt="close-icon"
          />
        </div>

        <h1 className="text-accent font-helvetica  -tighter mt-2 mb-4 text-center text-xl sm:text-2xl lg:text-3xl font-bold leading-tight">
          Email Verification Code
        </h1>
        <div className="flex justify-center mb-4 sm:mb-6 items-center">
          <img src="/icon/email.svg" alt="Email Icon" />
          <p className="font-poppins text-sm  font-normal ml-2 leading-5 sm:leading-6 text-left">
            A verification code will be sent to{" "}
            <span className="font-poppins text-sm  font-bold leading-5 sm:leading-6 text-left">
              {/* {obfuscateEmail(user.email)} */} {maskedEmail}
            </span>
          </p>
        </div>
        <div className="flex space-x-2 sm:space-x-3 justify-center">
          {otp.map((digit, index) => (
            <input
              key={`otp-${index}`}
              ref={(el) => (otpRefs.current[index] = el)}
              type="text"
              value={digit}
              onKeyPress={handleKeyPress}
              onChange={(e) => handleOtpChange(e, index)}
              onKeyDown={(e) => handleOtpKeyDown(e, index)}
              onClick={() => handleOtpClick(index)}
              onPaste={index === 0 ? handlePaste : null}
              maxLength="1"
              className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 border border-primary text-primary bg-black rounded-md text-center text-lg sm:text-xl"
            />
          ))}
        </div>
        <div className="mx-[4%] flex gap-6 my-8">
          <button
            className={`w-full mx-auto border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
            onClick={onClose}
          >
            Back
          </button>
          <button
            className={`${
              loading ? "cursor-not-allowed opacity-50" : ""
            } w-full border border-primary text-black font-semibold bg-primary py-2 rounded`}
            onClick={handleVerifyOtp}
          >
            {loading ? (
              <img
                src="/icon/loader.svg"
                alt="Loading..."
                className="w-7 mx-auto h-7 animate-spin"
              />
            ) : (
              " Verify Email"
            )}
          </button>
        </div>
        {/* <div className="flex justify-center">
          <button
            className={`w-full sm:w-[50%] mt-4 border border-primary text-black font-semibold bg-primary py-2 rounded ${
              loading ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={handleVerifyOtp}
          >
            {loading ? (
              <img
                src="/icon/loader.svg"
                alt="Loading..."
                className="w-7 h-7 mx-auto animate-spin"
              />
            ) : (
              "Verify Email"
            )}
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default VerifyEmail;
