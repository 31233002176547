import React, { useContext, useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Notification from "./Notification";
import { Link, useNavigate } from "react-router-dom";
import { postRequest, registerUser } from "../backendServices/ApiCalls";
import Select from "react-select";
import { countries } from "countries-list";
import { UserContext } from "../contexts/UserContext";
const excludedCountries = [
  "United States",
  "China",
  "Hong Kong",
  "Singapore",
  "Canada",
  "France",
  "United Kingdom",
  "North Korea",
  "Cuba",
  "Iran",
  "Uzbekistan",
  "Ukraine",
  "Sevastopol",
  "Sudan",
  "Syria",
];
const countryOptions = Object.values(countries)
  .map((country) => ({
    value: country.name,
    label: country.name,
  }))
  .filter((country) => !excludedCountries.includes(country.label));
const validationSchema2 = Yup.object({
  address: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  state: Yup.string().required("State is required"),
  zipcode: Yup.string()
    .matches(/^[0-9]+$/, "Zipcode must be a number")
    .required("Zipcode is required"),
});
const validationSchema3 = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  terms: Yup.boolean().oneOf([true], "You must accept the Terms of Service"),
  riskDisclaimer: Yup.boolean().oneOf(
    [true],
    "You must agree to the Risk Disclaimer"
  ),
  marketingConsent: Yup.boolean().oneOf(
    [true],
    "You must agree to marketing purposes"
  ),
});
const initialValues = {
  name: "",
  lastname: "",
  email: "",
  dob: "",
  country: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
};
function SignupCard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(initialValues);
  const [currentStep, setCurrentStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { setUser } = useContext(UserContext);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    lastname: Yup.string().required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .test("unique-email", "Email already exists", function (value) {
        if (!value) return false;
        return new Promise((resolve) => {
          postRequest(
            "no",
            "auth/checkemailexists",
            { email: value },
            (response) => {
              resolve(true);
            },
            (error) => {
              resolve(false);
            }
          );
        });
      }),
    dob: Yup.date()
      .required("Date of Birth is required")
      .test("is-18", "You must be at least 18 years old", (value) => {
        if (!value) return false;
        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        const dayDiff = today.getDate() - birthDate.getDate();
        return (
          age > 18 ||
          (age === 18 && (monthDiff > 0 || (monthDiff === 0 && dayDiff >= 0)))
        );
      }),
    country: Yup.string().required("Country is required"),
  });
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleStepOneSubmit = (values) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...values }));
    setCurrentStep(2);
  };

  const handleStepTwoSubmit = (values) => {
    setFormData((prevFormData) => ({ ...prevFormData, ...values }));
    setCurrentStep(3);
  };
  const formatDob = (value) => {
    const date = new Date(value);
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleSignup = async (values) => {
    const fullData = { ...formData, ...values };
    const formattedData = {
      ...fullData,
      dob: formatDob(fullData.dob),
    };
    try {
      setLoading(true);
      if (!navigator.onLine) {
        Notification.showNotification(
          "error",
          "Network Error",
          "Check your internet connection.",
          setLoading(false)
        );
        return;
      }
      registerUser(
        { postData: formattedData },
        (response) => {
          if (response?.status === 200) {
            Notification.showNotification(
              "success",
              "Success",
              "User registered successfully."
            );
          }
          localStorage.setItem("token", response?.data?.token);
          setUser(response?.data?.user);
          navigate("/dashboard");
          setLoading(false);
        },
        (error) => {
          Notification.showNotification(
            "error",
            "Error",
            error.response?.data?.message || error.message
          );
          console.error(
            "Error:",
            error.response?.data?.message || error.message
          );
          setLoading(false);
        }
      );
    } catch (error) {
      console.error("Unexpected Error:", error.message);
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignup();
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="mx-2">
      <h1 className="text-accent font-sora mt-16 mb-0 text-center text-6xl font-bold leading-normal">
        Create An Account
      </h1>
      <div className="max-w-[488px] mt-6 pb-2 pt-6 h-auto bg-[#222222] mx-auto rounded-3xl">
        <div className="flex mx-auto w-[80%]">
          {currentStep > 1 ? (
            <img
              src="/icon/backsignup.png"
              className="w-2 h-3 my-auto cursor-pointer"
              onClick={handleBack}
            />
          ) : (
            <div className="w-2 h-3"></div>
          )}
          <div className="w-16 h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
            <img
              src="/icon/yellowProfile.png"
              className="w-6 h-6"
              alt="Profile"
            />
          </div>
        </div>
        <div className="container" style={{ overflow: "unset" }}>
          {currentStep === 1 && (
            <div>
              <div className="flex gap-2 justify-center items-center mt-8">
                <div className="w-2 h-2 bg-primary rounded-full"></div>
                <div className="w-2 h-2 bg-[#FFBF0029] rounded-full"></div>
                <div className="w-2 h-2 bg-[#FFBF0029] rounded-full"></div>
              </div>
              <div className="mx-8 mt-4">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema}
                  onSubmit={handleStepOneSubmit}
                >
                  {({ setFieldValue }) => (
                    <Form>
                      <div>
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Name*
                        </p>
                        <Field
                          type="text"
                          name="name"
                          placeholder="Enter name"
                          className="border mt-1 px-4 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Last Name*
                        </p>
                        <Field
                          type="text"
                          name="lastname"
                          placeholder="Enter Last name"
                          className="border mt-1 px-4 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Email*
                        </p>
                        <Field
                          type="email"
                          name="email"
                          placeholder="Enter email"
                          className="border mt-1 px-4 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-6">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Date of Birth*
                        </p>
                        <Field
                          type="date"
                          name="dob"
                          placeholder="Enter DOB"
                          className="border mt-1 px-4 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="dob"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Country of residence*
                        </p>
                        <div className="border mt-1 rounded-xl border-[#383838] w-full bg-transparent">
                          <Select
                            options={countryOptions}
                            onChange={(option) =>
                              setFieldValue("country", option.value)
                            }
                            isSearchable
                            placeholder="Select an accepted country"
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                backgroundColor: "transparent",
                                border: "none",
                                boxShadow: "none",
                                padding: "0 0.5rem",
                                height: "44px",
                                fontSize: "14px",
                                color: "#9B9B9B",
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                color: "#9B9B9B",
                                fontWeight: "normal",
                                fontSize: "14px",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                backgroundColor: "#222222",
                                boxShadow: "none",
                                border: "1px solid #383838",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused
                                  ? "#383838"
                                  : "transparent",
                                color: "#fff",
                                padding: "10px",
                                cursor: "pointer",
                              }),
                              input: (provided) => ({
                                ...provided,
                                color: "#fff",
                              }),
                            }}
                          />
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="text-red-500 text-sm"
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="bg-primary w-[100%] font-poppins mt-4 text-[#0C0C0C] rounded-xl text-[14px] py-2 sm:py-4 mx-auto flex items-center justify-center"
                      >
                        Continue
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <div className="flex gap-2 justify-center items-center mt-8">
                <div className="w-2 h-2 bg-[#FFBF0029] rounded-full"></div>
                <div className="w-2 h-2 bg-primary rounded-full"></div>
                <div className="w-2 h-2 bg-[#FFBF0029] rounded-full"></div>
              </div>
              <div className="mx-8 mt-4">
                <Formik
                  initialValues={formData}
                  validationSchema={validationSchema2}
                  onSubmit={handleStepTwoSubmit}
                >
                  {() => (
                    <Form>
                      <div>
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Address*
                        </p>
                        <Field
                          type="text"
                          name="address"
                          placeholder="Street address, P.O. box"
                          className="border mt-1 px-2 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="address"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Address 2 (Opt)
                        </p>
                        <Field
                          type="text"
                          name="address2"
                          placeholder="Apartment, suite, unit, building, floor"
                          className="border mt-1 px-2 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="address2"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          City*
                        </p>
                        <Field
                          type="text"
                          name="city"
                          placeholder="Enter city"
                          className="border mt-1 px-2 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          State*
                        </p>
                        <Field
                          type="text"
                          name="state"
                          placeholder="Select State"
                          className="border mt-1 px-2 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className=" mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Zipcode*
                        </p>
                        <Field
                          type="text"
                          name="zipcode"
                          className="border mt-1 px-2 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-36 bg-transparent text-accent"
                        />
                        <ErrorMessage
                          name="zipcode"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <button
                        type="submit"
                        className="bg-primary w-[100%] font-poppins mt-4 text-[#0C0C0C] rounded-xl text-[14px] py-2 sm:py-4 mx-auto flex items-center justify-center"
                      >
                        Save Address & Continue
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
          {currentStep === 3 && (
            <div>
              <div className="flex gap-2 justify-center items-center mt-8">
                <div className="w-2 h-2 bg-[#FFBF0029] rounded-full"></div>
                <div className="w-2 h-2 bg-[#FFBF0029] rounded-full"></div>
                <div className="w-2 h-2 bg-primary rounded-full"></div>
              </div>
              <div className="mx-8 mt-4">
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                    terms: false,
                    riskDisclaimer: false,
                    marketingConsent: false,
                  }}
                  validationSchema={validationSchema3}
                  onSubmit={(values) => {
                    handleSignup(values);
                  }}
                >
                  {({ values, handleChange }) => (
                    <Form>
                      <div className="mt-4">
                        <p className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block">
                          Email
                        </p>
                        <p className="mt-1 px-4 h-11 rounded-xl bg-[#323232] text-[14px] text-[#9B9B9B] font-normal border border-[#383838] w-full bg-transparent flex items-center">
                          {formData.email}
                        </p>
                      </div>
                      <div className=" mt-4">
                        <label
                          htmlFor="password"
                          className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block"
                        >
                          Password*
                        </label>
                        <div className="relative">
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Enter password"
                            className="border mt-1 px-4 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
                          >
                            <img
                              src={`/icon/${
                                showPassword ? "openeye" : "closeeye"
                              }.svg`}
                              alt="Toggle visibility"
                              className="size-5"
                            />
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="p"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>
                      <div className=" mt-4">
                        <label
                          htmlFor="confirmPassword"
                          className="text-accent font-sora  -tighter mb-0 text-lg font-medium leading-relaxed block"
                        >
                          Confirm Password*
                        </label>
                        <div className="relative">
                          <Field
                            type={showConfirmPassword ? "text" : "password"}
                            name="confirmPassword"
                            onClick={handleKeyPress}
                            placeholder="Confirm password"
                            className="border mt-1 px-4 h-11 rounded-xl placeholder:text-[14px] placeholder:text-[#9B9B9B] placeholder:font-normal border-[#383838] w-full bg-transparent text-accent"
                          />
                          <span
                            onClick={toggleConfirmPasswordVisibility}
                            className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-accent"
                          >
                            <img
                              src={`/icon/${
                                showConfirmPassword ? "openeye" : "closeeye"
                              }.svg`}
                              alt="Toggle visibility"
                              className="size-5"
                            />
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="p"
                          className="text-red-500 text-sm mt-1"
                        />
                      </div>
                      <div className="flex ">
                        <Checkbox
                          checked={values.terms}
                          onChange={handleChange}
                          name="terms"
                          sx={{
                            color: "black",
                            "&.Mui-checked": {
                              color: "#FFBF00",
                            },
                          }}
                        />
                        <p className="mt-3 text-[#9B9B9B] font-sora text-[14px]">
                          I accept the
                          <Link
                            to="/termsandconditions"
                            className="text-primary"
                          >
                            {" "}
                            Terms of Service{" "}
                          </Link>
                          and the{" "}
                          <Link to="/privacypolicy" className="text-primary">
                            Privacy Policy
                          </Link>
                        </p>
                      </div>
                      <ErrorMessage
                        name="terms"
                        component="p"
                        className="text-red-500 text-sm mt-1"
                      />

                      <div className="flex ">
                        <Checkbox
                          checked={values.riskDisclaimer}
                          onChange={handleChange}
                          name="riskDisclaimer"
                          sx={{
                            color: "black",
                            "&.Mui-checked": {
                              color: "#FFBF00",
                            },
                          }}
                        />
                        <p className="mt-3 text-[#9B9B9B] font-sora text-[14px]">
                          I have read and agree to the
                          <Link to="/guideline" className="text-primary">
                            {" "}
                            Risk Disclaimer
                          </Link>
                        </p>
                      </div>
                      <ErrorMessage
                        name="riskDisclaimer"
                        component="p"
                        className="text-red-500 text-sm mt-1"
                      />

                      <div className="flex ">
                        <Checkbox
                          checked={values.marketingConsent}
                          onChange={handleChange}
                          name="marketingConsent"
                          sx={{
                            color: "black",
                            "&.Mui-checked": {
                              color: "#FFBF00",
                            },
                          }}
                        />
                        <p className="mt-3 text-[#9B9B9B] font-sora text-[14px]">
                          I agree that AlgoX Capital may reach out to me for
                          marketing purposes.
                        </p>
                      </div>
                      <ErrorMessage
                        name="marketingConsent"
                        component="p"
                        className="text-red-500 text-sm mt-1"
                      />

                      <button
                        type="submit"
                        disabled={loading}
                        className={`bg-primary w-[98%] mt-4 text-[#0C0C0C] font-poppins  rounded-xl text-[14px] py-2 sm:py-4 mx-auto flex items-center justify-center `}
                      >
                        {loading ? (
                          <img
                            src="/icon/loader.svg"
                            alt="Loading..."
                            className="w-7 mx-auto h-7 animate-spin"
                          />
                        ) : (
                          " Create your free Account"
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
        <p className="font-sora text-xs font-normal w-[86%] mx-auto my-6 text-[#9B9B9B] text-left">
          *Mandatory fields
        </p>
      </div>
    </div>
  );
}

export default SignupCard;
