import React, { useEffect } from "react";

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
function DisclaimerPage() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="account">
      <div className="bg-account">
        <Navbar />

        <div className=" mx-4 sm:mx-12 md:mx-20 mt-20 2xl:w-4/5 2xl:mx-auto">
          <h1 className="font-sora text-5xl font-bold leading-[62.72px]  -[-0.03em] text-center">
          Risk Disclaimer
          </h1>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-12">
            Introduction and Legal framework
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            This file includes all the relevant information regarding the
            requirements for an imprint and to fulfill the legal requirements
            for documentation, transparency and to remain within the current
            legal framework provided by the State of Germany as well as on
            European level.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Based on our core principles we see it as our duty to our customers
            to have a fully transparent framework for the use of our services.
            We want to point out that to our best efforts, we try to be
            compliant with all the herein mentioned as well as not mentioned
            standards, which are applicable for our area of services. However,
            to all our efforts, we cannot always guarantee to be compliant with
            every standard applicable in each country of business.
          </p>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-4">
            We clearly need to point out, that past performance does not
            guarantee future success!
          </h2>
          <h2 className="font-sora text-xl font-semibold leading-[26.88px]  -[-0.02em] text-left mt-4">
            Risk warning for users
          </h2>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            AlgoX Capital GbR is a joint venture of APDK Capital UG (HRB 32072)
            and ChainX Studio UG (HRB12345). AlgoX Capital GbR is not entered in
            the commercial register as a partnership under the civil law of
            Germany. The partners of the company are personally, directly,
            jointly and severally liable to an unlimited extent. We would like
            to point out that the holding companies (both UGs (limited by
            liability)) nevertheless provide a complete liability shield and
            that, in the event of damages, liability is limited to the assets of
            the partners, i.e. the UGs.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            Before you decide to use the services of AlgoX Capital, represented
            by its founders, Philipp Deutsch, Florian ABC, Lion Degen and Arben
            Kuqi, registered under 83556 Griesstätt, Sperberweg 13, Germany, you
            are required to take note of the following risk warnings and to
            accept them as an integral part of the contract.
          </p>
          <div className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            <p>
              We would like to point out that the services we provide do not
              constitute any kind of investment or financial advice. At the time
              of creating this documentation, we are not an officially licensed
              financial service provider, nor are we subject to any licensing or
              notification requirements from the supervisory authorities. In
              this context, we would like to point out that we do not
              participate in the European Deposit Guarantee Scheme. In this
              context, we would like to point out that we have no access to the
              funds and that the funds are managed solely by our partners such
              as Bybit, Binance and others, and that access is only obtained by
              the respective account holder. The provision of our services to
              the general public is beyond the scope of active financial advice.
              We do not provide services that are specifically customized to a
              client's needs and risk profile. Investments involve a number of
              risks and are therefore not suitable for every investor. The risks
              associated with a particular investment can be seen by you as a
              user or customer of the respective offer listed on{" "}
              <a
                target="_blank"
                href="/www.AlgoX.Capital"
                className="text-primary"
              >
                www.AlgoX.Capital
              </a>
              . When you invest in our services, you need to be aware of the
              following:
            </p>
            <ul className="list-disc pl-5">
              <li className="mt-2">
                You acknowledge and agree that AlgoX Capital does not sell or
                otherwise distribute or procure cryptocurrencies directly,
                themselves or through third parties. Rather, AlgoX Capital
                enables you to participate in Copy Trading services for
                different financial products, via the{" "}
                <a
                  target="_blank"
                  href="/www.AlgoX.Capital"
                  className="text-primary"
                >
                  www.AlgoX.Capital
                </a>{" "}
                platform and makes the framework available for you to be able to
                invest in projects. AlgoX Capital does not provide investment-
                financial advice neither other advice which is requires further
                regulations. Also, AlgoX Capital makes no investment
                recommendation, but merely offers a platform for copy trading.
                Above all, if you are investing in a so-called start-up, you are
                more likely to lose your invested capital than to make a return
                on your invested capital. As an investor, you bear the
                entrepreneurial risk in the amount of your investment. With
                every investment a total loss of the invested capital is
                possible.
              </li>
              <li className="mt-2">
                You further expressly acknowledge and agree that you are fully
                aware and well informed that the market for cryptocurrencies is
                of a high degree of volatility and that this market is both
                extremely speculative and owning or trading cryptocurrencies
                carries a significant risk that can result in a significant loss
                in the shortest possible time and, in the worst case, a total
                loss of the value of the held cryptocurrencies / assets
              </li>
              <li className="mt-2">
                You expressly acknowledge and agree that, beyond the terms of
                the agreement, AlgoX Capital makes no representations,
                warranties or representations of any kind whatsoever regarding
                profit expectations, performance or other proceeds in connection
                with cryptocurrencies.
              </li>
              <li className="mt-2">
                In particular, before conclusion of your agreements, you
                expressly acknowledge and agree that you are an experienced
                business person in the field of cryptocurrencies and the
                economic use of these currencies, and
                <ul className="list-disc pl-5">
                  <li className="mt-2">
                    you expressly understand and agree to bear the economic,
                    legal, tax and other risks with respect to the
                    cryptocurrencies in your possession,
                  </li>
                  <li className="mt-2">
                    you are financially able, bearing in mind your personal
                    financial circumstances, financial resources, your personal
                    lifestyle and your monetary obligations, to incur the loss
                    of all your expenses in connection with the use of the
                    services offered by AlgoX Capital, without doing so, the
                    case of a total loss of the value of your cryptocurrencies
                    will significantly burden your economic life,
                  </li>
                  <li className="mt-2">
                    you have knowledge of the respective trading of assets and
                    their value-determining factors that you have for yourself,
                  </li>
                  <li className="mt-2">
                    You expressly acknowledge and agree that AlgoX Capital does
                    not provide you with advice on cryptocurrencies or the
                    cryptocurrency markets, but AlgoX Capital only offers the
                    copy trading and provides the framework through the platform{" "}
                    <a
                      target="_blank"
                      href="/www.AlgoX.Capital"
                      className="text-primary"
                    >
                      www.AlgoX.Capital
                    </a>{" "}
                    in order to invest in projects of us.{" "}
                  </li>
                  <li className="mt-2">
                    You expressly acknowledge and agree that it is possible
                    under the laws, governmental understandings or jurisdiction
                    that apply to you under your national law that the
                    acquisition, possession or trading of cryptocurrencies is
                    not legal or is only legal with permission and you are
                    responsible for ensuring that the use and exploitation of
                    the services of AlgoX Capital is permitted under the
                    applicable law.
                  </li>
                </ul>
              </li>

              <li className="mt-2">
                You expressly acknowledge and agree that untrue or speculative
                statements or forecasts, or an application for the services of
                AlgoX Capital as an asset, monetary or financial investment that
                could be used by third parties without permission to disseminate
                and use the services of AlgoX Capital are untrue and unlawful,
                are therefore strictly prohibited.
              </li>
              <li className="mt-2">
                You expressly acknowledge and agree that AlgoX Capital rejects
                all statements made by third parties about its services and that
                AlgoX Capital advises users not to rely on any third-party
                statements relating to the services of AlgoX Capital or the
                property or refer to the trading of cryptocurrencies - whether
                positive or negative.
              </li>
              <li className="mt-2">
                AlgoX Capital shall not be liable for the occurrence of the
                desired result which the user or his contractual partner wishes
                to achieve with the services offered by AlgoX Capital.
              </li>
              <li className="mt-2">
                Observance of the high risk of ownership or trading of
                cryptocurrencies referred to in paragraphs (1) to (4) and (5) to
                (7), AlgoX Capital makes no expression or implied warranty or
                guarantee, and makes no representation of liability that users
                could not face a total loss. As company, we also do not
                guarantee or provide warranty, that the ownership or trading of
                cryptocurrencies in the country of residence of each user is
                legally permitted. We point out, that this question needs to be
                determined by each user itself independently.
              </li>
              <li className="mt-2">
                You expressly acknowledge and agree that, as stated in (6),
                AlgoX Capital is entitled to restrict or terminate its services,
                in whole or in part, for individual markets at any time without
                prior notice, and in the event of termination, to terminate the
                contractual relationship with you extraordinarily and without
                notice.
              </li>
            </ul>
          </div>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            We expressly point out that historical data, neither from live
            trading nor from backtesting, does allow any conclusions to be drawn
            about future developments.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
            According to German jurisdiction, so-called miscellaneous income
            could exist. This would be taxed at the personal income tax rate
            according to German jurisdiction. We would like to point out that
            this is not tax or legal advice. We recommend prior consultation
            with your legal advisor such as a certified tax advisor, auditor,
            lawyer or your financial or investment advisor.
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
          @AlgoX Capital 2024
          </p>
          <p className="font-sora text-base font-normal leading-6 text-[#9B9B9B] mt-4 text-left">
          All rights reserved
          </p>
        </div>
      </div>
      {/* <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[16px] md:hidden text-[#B0A4FF] mt-64 sm:mt-[45%] 2xl:mt-96 font-normal leading-[22.4px]  -[4.8px] text-center"
      >
        HAVE QUESTION?
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="200"
        className="font-sora text-[44px] mt-3 md:hidden font-semibold leading-[49.28px]  -[-0.88px] text-center"
      >
        Frequently Asked <br />
        Question
      </p>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="lg:hidden"
      >
       <AccountFaqs question="What payment methods do you accept?" />
        <AccountFaqs question="Are there any hidden fees?" />
        <AccountFaqs question="Is there a free trial available?" />
        <AccountFaqs question="How do I get started?" />
        <AccountFaqs question="How do I access my account?" />
      </div> */}

      {/* <ElevateBusiness /> */}

      <Footer />
    </div>
  );
}
export default DisclaimerPage;
