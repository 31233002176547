import React, { useContext, useEffect, useRef, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import Notification from "./Notification";
import { UserContext } from "../contexts/UserContext";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import BigLoader from "./BigLoader";
import { postRequest } from "../backendServices/ApiCalls";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Verification2FaModal({
  faModalOpen,
  faModalOpenfalse,
  closeverificationModal,
  secret: secretProp,
}) {
  const { user, setUser } = useContext(UserContext);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const secret = secretProp?.secret;
  const faValue = secretProp?.faValue;

  useEffect(() => {
    if (otp.join("").length === 6) {
      handleOtpSubmit();
    }
  }, [otp]);

  useEffect(() => {
    if (faValue === false) {
      setOtpModalOpen(true);
    }
  }, [faValue]);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Focus next field
      if (index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (!otp[index]) {
        // Focus previous field if empty
        if (index > 0) {
          otpRefs.current[index - 1]?.focus();
        }
      } else {
        // Clear current field
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleOtpClick = (index) => {
    otpRefs.current[index]?.focus();
  };

  const handleOtpSubmit = () => {
    if (otp.join("").length === 6) {
      setLoading(true);
      const otpToken = otp.join("");
      postRequest(
        "yes",
        "auth/verifyfa", // API endpoint
        { token: otpToken, faValue }, // Request payload
        (response) => {
          if (response.data.isVerified) {
            if (response.data.faValue === true) {
              Notification.showNotification(
                "success",
                "Success",
                "2FA Verification enabled successfully on enable Google 2FA."
              );
            }
            if (response.data.faValue === false) {
              Notification.showNotification(
                "success",
                "Success",
                "2FA Verification disabled successfully on disable Google 2FA."
              );
            }
            closeverificationModal();
            setOtpModalOpen(false);
            setUser((prevUser) => ({
              ...prevUser,
              FAverified: response.data.faValue,
            }));
            setOtp(Array(6).fill(""));
          } else {
            Notification.showNotification("error", "Error", "Invalid Code.");
            setOtp(Array(6).fill(""));
          }
          setLoading(false);
        },
        (error) => {
          console.error("Error verifying OTP:", error);
          Notification.showNotification(
            "error",
            "Error",
            "An error occurred during verification."
          );
          setOtp(Array(6).fill("")); // Reset OTP fields
          setLoading(false); // Reset loading state
        }
      );
    } else {
      setMessage("Please enter a valid 6-digit OTP.");
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(secret.base32)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleOtpSubmit();
    }
  };

  const Images = {
    close: "./icon/CDM.svg",
  };

  return (
    <>
      {faModalOpen && (
        <div className="fixed inset-0 flex px-1 items-center justify-center z-50  backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
          <div className="w-full max-w-[738px] px-4 sm:px-6 py-8 bg-[#211E34E5] border-[#FFFFFF26] border rounded-3xl relative">
            <div className="flex justify-between">
              <p className="font-sora text-xl font-bold leading-[20.16px] text-left text-white">
                2FA Verification Setup
              </p>
              <img
                src={Images.close}
                width={20}
                height={20}
                onClick={closeverificationModal}
                className="cursor-pointer"
                alt="close-icon"
              />
            </div>
            <p className="font-helvetica text-[12px] font-normal leading-[24px] text-left text-accent2">
              Set up the Authenticator of your choice. We support{" "}
              <span className="text-primary">
                {" "}
                Google Authenticator, Microsoft Authenticator.
              </span>
            </p>
            {secret ? (
              <>
                <div className="rounded-lg bg-[#3F3C4F] px-4 py-7 mt-6">
                  <div className="flex flex-col md:flex-row gap-4">
                    <QRCodeSVG
                      value={secret.otpauth_url}
                      bgColor="#ffffff"
                      fgColor="#000000"
                      level="Q"
                      includeMargin={true}
                      className="rounded-lg mx-auto md:mx-0"
                      width={100}
                      height={100}
                    />
                    <div className="my-auto">
                      <p className="font-sora text-[12px] font-normal leading-[15.12px] text-left text-[#FFFFFFBF]">
                        Secret Key
                      </p>
                      <div className="w-full md:w-[160%] border relative mt-2 border-[#FFFFFF80] rounded flex h-10 my-auto">
                        {copied && (
                          <div className="absolute top-[-30px] left-1/2 transform -translate-x-1/2 bg-black text-accent text-sm rounded-md px-2 py-1">
                            Copied!
                          </div>
                        )}
                        <input
                          type="text"
                          value={secret.base32}
                          readOnly
                          className="w-full cursor-pointer text-left bg-[#3F3C4F] border-none text-[#FFFFFFBF] rounded-md p-2"
                          onClick={(e) => e.target.select()}
                        />
                        <button
                          className="flex items-center justify-center bg-[#00000026] m-1 p-2 rounded"
                          aria-label="Copy secret key flex"
                          onClick={copyToClipboard}
                        >
                          {copied ? (
                            <CheckCircleIcon className="cursor-pointer text-green-500" />
                          ) : (
                            <ContentCopyIcon className="cursor-pointer" />
                          )}
                          <p className="font-sora text-[10px] font-normal w-20 leading-[12.6px] text-center">
                            Copy Address
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 justify-center items-center mt-6">
                  <div className={`w-1 h-1 rounded-full bg-[#fff1f1b8]`}></div>
                  <div className={`w-1 h-1 rounded-full bg-[#6d6d6d]`}></div>
                </div>
                <div className="flex justify-between my-8">
                  <button
                    className={` px-[12%] border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                    onClick={closeverificationModal}
                  >
                    Cancel
                  </button>
                  <button
                    className={`${
                      loading ? "cursor-not-allowed opacity-50" : ""
                    } px-[10%] border border-primary text-black font-semibold bg-primary py-2 rounded`}
                    onClick={() => setOtpModalOpen(true)}
                  >
                    Next Step
                  </button>
                </div>
              </>
            ) : (
              <div className="flex justify-center mt-2">
                <BigLoader />
              </div>
            )}

            {/* <div className="relative flex justify-end items-center p-4">
              <img
                src={Images.close}
                width={20}
                height={20}
                onClick={closeverificationModal}
                className="absolute cursor-pointer"
                alt="close-icon"
              />
            </div>

            {secret ? (
              <>
                <h1 className="text-white font-helvetica mb-2 text-2xl font-bold text-center">
                  2FA Verification
                </h1>
                <div className="flex flex-col items-center">
                  <QRCodeSVG
                    value={secret.otpauth_url}
                    bgColor="#ffffff"
                    fgColor="#000000"
                    level="Q"
                    includeMargin={true}
                    className=""
                    width={150}
                    height={150}
                  />
                  <div className="mt-4 text-white text-center">
                    <h2 className="text-xl">Scan the QR Code</h2>
                    <p className="break-words mb-2">Secret Key:</p>
                    <div className="relative" onClick={copyToClipboard}>
                      {copied && (
                        <div className="absolute top-[-25px] left-1/2 transform -translate-x-1/2 bg-black text-accent text-sm rounded-md px-2 py-1">
                          Copied!
                        </div>
                      )}
                      <input
                        type="text"
                        value={secret.base32}
                        readOnly
                        className="w-full cursor-pointer md:w-96 text-center bg-black text-white border border-gray-600 rounded-md pl-2 pr-9 py-2"
                        onClick={(e) => e.target.select()}
                      />
                      <button
                        className="absolute right-2 top-2 text-white ml-2"
                        aria-label="Copy secret key"
                      >
                        <ContentCopyIcon />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mx-[4%] flex gap-4 my-8">
                  <button
                    className={`w-full mx-auto border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                    onClick={closeverificationModal}
                  >
                    Back
                  </button>
                  <button
                    className={`${
                      loading ? "cursor-not-allowed opacity-50" : ""
                    } w-full border border-primary text-black font-semibold bg-primary py-2 rounded`}
                    onClick={() => {
                      setOtpModalOpen(true);
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <img
                        src="/icon/loader.svg"
                        alt="Loading..."
                        className="w-7 mx-auto h-7 animate-spin"
                      />
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
              </>
            ) : (
              <div className="flex justify-center mt-2">
                <BigLoader />
              </div>
            )} */}
          </div>
        </div>
      )}
      {otpModalOpen && (
        <div className="fixed inset-0 flex px-1 items-center justify-center z-50  backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
          <div className="w-full max-w-[738px] px-4 sm:px-6 py-8 bg-[#211E34E5] border-[#FFFFFF26] border rounded-3xl relative">
            <div className="flex justify-between">
              <p className="font-sora text-xl font-bold leading-[20.16px] text-left text-white">
                2FA Verification Setup
              </p>
              <img
                src={Images.close}
                width={20}
                height={20}
                onClick={() => {
                  if (faValue === true) {
                    setOtpModalOpen(false);
                  }
                }}
                className="cursor-pointer"
                alt="close-icon"
              />
            </div>
            <p className="font-helvetica text-[12px] font-normal leading-[24px] text-left text-accent2">
              Set up the Authenticator of your choice. We support{" "}
              <span className="text-primary">
                {" "}
                Google Authenticator, Microsoft Authenticator.
              </span>
            </p>
            <div className="rounded-lg bg-[#3F3C4F] px-4 py-7 mt-6">
              <p className="font-title-medium font-sora text-title-medium text-center">
                Enter Code
              </p>
              <div className="flex justify-center mt-2 space-x-2 sm:space-x-4">
                {otp.map((digit, index) => (
                  <input
                    key={`otp-${index}`}
                    ref={(el) => (otpRefs.current[index] = el)}
                    type="text"
                    value={digit}
                    onKeyPress={handleKeyPress}
                    onChange={(e) => handleOtpChange(e, index)}
                    onKeyDown={(e) => handleOtpKeyDown(e, index)}
                    onClick={() => handleOtpClick(index)}
                    maxLength="1"
                    className="h-10 w-10 sm:w-16 sm:h-16 border border-accent2 text-primary bg-transparent rounded-md text-center font-bold text-lg sm:text-2xl"
                  />
                ))}
              </div>
            </div>
            {message && (
              <p className="text-center mt-2 text-red-500">{message}</p>
            )}
            <div className="flex gap-2 justify-center items-center mt-6">
              <div className={`w-1 h-1 rounded-full bg-[#6d6d6d]`}></div>
              <div className={`w-1 h-1 rounded-full bg-[#fff1f1b8]`}></div>
            </div>
            <div className="justify-between flex  my-8">
              <button
                className={`px-[12%] border ${
                  faValue === false && "cursor-not-allowed"
                } border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                onClick={() => {
                  if (faValue === true) {
                    setOtpModalOpen(false);
                  }
                }}
              >
                Cancel
              </button>
              <button
                className={`${
                  loading ? "cursor-not-allowed opacity-50" : ""
                } px-[2%] sm:px-[8%] text-[12px] sm:text-[16px] border border-primary text-black font-semibold bg-primary py-2 rounded`}
                onClick={handleOtpSubmit}
                disabled={loading}
              >
                {loading ? (
                  <img
                    src="/icon/loader.svg"
                    alt="Loading..."
                    className="w-7 mx-auto h-7 animate-spin"
                  />
                ) : (
                  "Confirm Method "
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Verification2FaModal;
