import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import PurchaseCard from "../../components/PurchaseCard";
import PurchaseHeader from "../../components/PurchaseHeader";

function Purchase() {
  return (
    <div className="overflow-x-hidden  bg-accent">
      <div className="shop-div">
        <Navbar />
        <PurchaseHeader text="Shop and Subscription" />
      </div>
      <div class="text-opacity-75 ">
        <p class="font-transducer-test mx-2 mt-[73px] text-[#0C0C0C] mb-[73px] text-center text-4xl font-extrabold leading-tight capitalize">
          The Algo-Trading Full Service
        </p>
      </div>
      <div className=" bg-gray mx-[2px] sm:mx-8 mb-28 rounded-2xl h-auto ">
        <div className="h-28"></div>
        <PurchaseCard />
        <PurchaseCard />
        <PurchaseCard />
        <PurchaseCard />
        <PurchaseCard />
        <PurchaseCard />
      </div>
      <div class="text-opacity-75">
        <p class="font-transducer-test mt-[73px] text-[#0C0C0C] mb-[73px] text-center text-4xl font-extrabold leading-tight capitalize">
          The Copy-Trading Service
        </p>
      </div>
      <div className=" bg-gray mx-[2px] sm:mx-8 mb-28 rounded-2xl h-auto ">
        <div className="h-28"></div>
        <PurchaseCard />
        <PurchaseCard />
        <PurchaseCard />
        <PurchaseCard />
      </div>
      <div class="text-opacity-75">
        <p class="font-transducer-test mt-[73px] text-[#0C0C0C] mb-[73px] text-center text-4xl font-extrabold leading-tight capitalize">
          Market making CEX
        </p>
      </div>
      <div className=" bg-gray mx-[2px] sm:mx-8 mb-28 rounded-2xl h-auto">
        <div className="pt-40 px-4 lg:px-32 pb-20">
          <div className="flex">
            <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light min-w-16 sm:min-w-32  leading-[45px] text-left text-accent">
              Pool
            </p>
            <div className="flex gap-4 md:gap-12 xl:gap-24 sm:ml-auto">
              <div className="flex gap-2">
                <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                  APY
                </p>
                <div className="my-auto gap-2 grid">
                  <img src="./icon/purchaseuparrow.png" alt="purchaseuparrow" className="h-2 w-3" />
                  <img src="./icon/purchasedownarrow.png" alt="purchasedownarrow" className="h-2 w-3" />
                </div>
              </div>
              <div className="flex gap-2">
                <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                  24H Volume
                </p>
                <div className="my-auto gap-2 grid">
                  <img src="./icon/purchaseuparrow.png" alt="purchaseuparrow" className="h-2 w-3" />
                  <img src="./icon/purchasedownarrow.png"alt="purchasedownarrow" className="h-2 w-3" />
                </div>
              </div>
              <div className="flex gap-2">
                <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                  TVL
                </p>
                <div className="my-auto gap-2 grid">
                  <img src="./icon/purchaseuparrow.png" alt="purchasedownarrow" className="h-2 w-3" />
                  <img src="./icon/purchasedownarrow.png" alt="purchasedownarrow" className="h-2 w-3" />
                </div>
              </div>
            </div>
            <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-right ml-auto text-accent">
              Action
            </p>
          </div>
          <div className="flex mt-20">
            <div className="flex min-w-16 sm:min-w-32">
              <img src="./icon/btc.png" alt="btc" className=" size-8 sm:size-16 ml-[-10px] mr-1 sm:mr-4" />
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] my-auto text-left text-accent">
                BTC
              </p>
            </div>
            <div className="flex gap-5 md:gap-16 xl:gap-28 mr-[-90px] sm:ml-auto">
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                5.00%
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>
            </div>
            <div className="transition cursor-pointer gap-1 sm:gap-2 ml-auto flex border-yellow-500 duration-500 ease-in-out hover:bg-Accent2 hover:text-black active:bg-Accent2 active:text-black min-w-[70px] md:w-[130px] xl:w-[164px] border border-Accent2 h-[45px] px-1 md:px-0 md:pl-4 py-3 text-center rounded-md">
              <img src="./icon/plus.png" alt="plus" className="size-2 sm:size-3 my-auto" />
              <h2 className="font-poppins text-[8px] sm:text-[10px] md:text-[15px] xl:text-[20px] font-medium leading-[30px] text-left text-primary">
                Add Liquidity
              </h2>
            </div>
          </div>
          <div className="flex mt-20">
            <div className="flex min-w-16 sm:min-w-32">
              <img src="./icon/eth.png" alt="eth" className=" size-8 sm:size-16 ml-[-10px] mr-1 sm:mr-4" />
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] my-auto text-left text-accent">
                ETH
              </p>
            </div>
            <div className="flex gap-5 md:gap-16 xl:gap-28 mr-[-90px] sm:ml-auto">
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                5.00%
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>
            </div>
            <div className="transition cursor-pointer gap-1 sm:gap-2 ml-auto flex border-yellow-500 duration-500 ease-in-out hover:bg-Accent2 hover:text-black active:bg-Accent2 active:text-black min-w-[70px] md:w-[130px] xl:w-[164px] border border-Accent2 h-[45px] px-1 md:px-0 md:pl-4 py-3 text-center rounded-md">
              <img src="./icon/plus.png" alt="plus" className="size-2 sm:size-3 my-auto" />
              <h2 className="font-poppins text-[8px] sm:text-[10px] md:text-[15px] xl:text-[20px] font-medium leading-[30px] text-left text-primary">
                Add Liquidity
              </h2>
            </div>
          </div>
          <p className="font-poppins text-[24px] sm:text-[36px] font-medium leading-[54px] text-center mb-16 text-accent mt-24">
            Automated Marketed Market Making (DEX)
          </p>
          <div className="flex">
            <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light min-w-16 sm:min-w-32  leading-[45px] text-left text-accent">
              Pool
            </p>
            <div className="flex gap-4 md:gap-12 xl:gap-24 sm:ml-auto">
              <div className="flex gap-2">
                <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                  APY
                </p>
                <div className="my-auto gap-2 grid">
                  <img src="./icon/purchaseuparrow.png" alt="purchaseuparrow" className="h-2 w-3" />
                  <img src="./icon/purchasedownarrow.png" alt="purchasedownarrow" className="h-2 w-3" />
                </div>
              </div>
              <div className="flex gap-2">
                <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                  24H Volume
                </p>
                <div className="my-auto gap-2 grid">
                  <img src="./icon/purchaseuparrow.png" alt="purchaseuparrow" className="h-2 w-3" />
                  <img src="./icon/purchasedownarrow.png" alt="purchasedownarrow" className="h-2 w-3" />
                </div>
              </div>
              <div className="flex gap-2">
                <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                  TVL
                </p>
                <div className="my-auto gap-2 grid">
                  <img src="./icon/purchaseuparrow.png" alt="purchaseuparrow" className="h-2 w-3" />
                  <img src="./icon/purchasedownarrow.png" alt="purchasedownarrow" className="h-2 w-3" />
                </div>
              </div>
            </div>
            <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-right ml-auto text-accent">
              Action
            </p>
          </div>
          <div className="flex mt-20">
            <div className="flex min-w-16 sm:min-w-32">
              <img src="./icon/btc.png" alt="btc" className=" size-8 sm:size-16 ml-[-10px] mr-1 sm:mr-4" />
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] my-auto text-left text-accent">
                BTC
              </p>
            </div>
            <div className="flex gap-5 md:gap-16 xl:gap-28 mr-[-90px] sm:ml-auto">
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                5.00%
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>
            </div>
            <div className="transition cursor-pointer gap-1 sm:gap-2 ml-auto flex border-yellow-500 duration-500 ease-in-out hover:bg-Accent2 hover:text-black active:bg-Accent2 active:text-black min-w-[70px] md:w-[130px] xl:w-[164px] border border-Accent2 h-[45px] px-1 md:px-0 md:pl-4 py-3 text-center rounded-md">
              <img src="./icon/plus.png" alt="plus" className="size-2 sm:size-3 my-auto" />
              <h2 className="font-poppins text-[8px] sm:text-[10px] md:text-[15px] xl:text-[20px] font-medium leading-[30px] text-left text-primary">
                Add Liquidity
              </h2>
            </div>
          </div>
          <div className="flex mt-20">
            <div className="flex min-w-16 sm:min-w-32">
              <img src="./icon/eth.png" alt="eth" className=" size-8 sm:size-16 ml-[-10px] mr-1 sm:mr-4" />
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] my-auto text-left text-accent">
                ETH
              </p>
            </div>
            <div className="flex gap-5 md:gap-16 xl:gap-28 mr-[-90px] sm:ml-auto">
              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                5.00%
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>

              <p className="font-sans text-[11px] md:text-[20px] xl:text-[30px] font-light leading-[45px] text-left text-accent">
                $357.0m
              </p>
            </div>
            <div className="transition cursor-pointer gap-1 sm:gap-2 ml-auto flex border-yellow-500 duration-500 ease-in-out hover:bg-Accent2 hover:text-black active:bg-Accent2 active:text-black min-w-[70px] md:w-[130px] xl:w-[164px] border border-Accent2 h-[45px] px-1 md:px-0 md:pl-4 py-3 text-center rounded-md">
              <img src="./icon/plus.png" alt="plus" className="size-2 sm:size-3 my-auto" />
              <h2 className="font-poppins text-[8px] sm:text-[10px] md:text-[15px] xl:text-[20px] font-medium leading-[30px] text-left text-primary">
                Add Liquidity
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black">
        <Footer />
      </div>
    </div>
  );
}

export default Purchase;
