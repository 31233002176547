// // import React, { useState, useRef, useEffect } from "react";

// // function Faqs({
// //   question = "",
// //   paragraphfirst = "",
// //   listItems = "",
// //   paragraph = "",
// // }) {
// //   const [isExpanded, setIsExpanded] = useState(false);
// //   const contentRef = useRef(null);

// //   const contentData = {
// //     question,
// //     paragraphfirst,
// //     listItems,
// //     paragraph,
// //   };

// //   const toggleExpand = () => {
// //     if (isExpanded) {
// //       contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;

// //       contentRef.current.getBoundingClientRect();

// //       contentRef.current.style.maxHeight = "0px";
// //       setTimeout(() => setIsExpanded(false), 1300);
// //     } else {
// //       setIsExpanded(true);
// //     }
// //   };

// //   useEffect(() => {
// //     if (isExpanded) {
// //       contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
// //     } else {
// //       contentRef.current.style.maxHeight = "0px";
// //     }
// //   }, [isExpanded]);

// import React, { useRef, useEffect } from "react";

// function Faqs({
//   question = "",
//   paragraphfirst = "",
//   listItems = "",
//   paragraph = "",
//   isExpanded,
//   onToggle,
// }) {

//   const contentData = {
//     question,
//     paragraphfirst,
//     listItems,
//     paragraph,
//   };

//   const contentRef = useRef(null);

//   useEffect(() => {
//     if (isExpanded) {
//       contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
//     } else {
//       contentRef.current.style.maxHeight = "0px";
//     }
//   }, [isExpanded]);

//   return (
//     <div className="flex items-center sm:mb-6 lg:mb-0 justify-center">
//       <div
//         className={`w-[85%] pr-1 sm:w-[50%] bg-secondary mx-auto border-secondary h-auto 3xl:mx-[350px] 4xl:mx-[490px] flex flex-col justify-between sm:mx-16 md:mx-24 lg:mx-32  sm:min-h-[90px] rounded-3xl border cursor-pointer`}
//         style={{ maxWidth: "calc(100% - 40px)" }}
//         onClick={onToggle}
//       >
//         <div className={`flex`}>
//           <img
//             src={`${isExpanded ? "/icon/minus.webp" : "/icon/plus.svg"}`}
//             className={`rounded-full ml-5 w-6 ${
//               isExpanded ? "bg-primary rounded-full h-6 my-auto" : ""
//             }`}
//             alt="plus icon"
//           />
//           <h1
//             className={`font-sora text-[22.4px] font-semibold leading-[25.09px]  -[-0.48px] text-left  ${
//               isExpanded ? "py-8" : "py-8"
//             }  ml-3`}
//           >
//             {question}
//           </h1>
//         </div>
//         <div
//           ref={contentRef}
//           className={`expandable ${
//             isExpanded ? "visible-content" : "hidden-content"
//           } mt-[-3.5%]`}
//           style={{
//             maxHeight: "0px",
//             overflow: "hidden",
//             transition: "max-height 1.3s ease-out",
//           }}
//         >
//           <div className="expandable-content py-8 pb-2 px-12">
//             {contentData.paragraphfirst}
//             <br />
//             <br />
//             {listItems.length > 0 && (
//               <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
//                 {listItems.map((item, index) => (
//                   <li key={index} style={{ paddingLeft: "5px" }}>
//                     {item}
//                   </li>
//                 ))}
//               </ul>
//             )}
//             <br />
//             {contentData.paragraph}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Faqs;

import React, { useRef, useEffect } from "react";

function Faqs({
  question = "",
  paragraphfirst = "",
  listItems = [],
  paragraph = "",
  isExpanded,
  onToggle,
}) {
  const contentRef = useRef(null);

  useEffect(() => {
    if (isExpanded) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
    } else {
      contentRef.current.style.maxHeight = "0px";
    }
  }, [isExpanded]);

  return (
    <div className="flex items-center justify-center sm:mb-6 lg:mb-0">
      <div
        className="w-[85%] sm:w-[50%] 2xl:w-[40%] mx-auto bg-secondary border border-secondary text-white rounded-3xl cursor-pointer flex flex-col transition-all duration-300 ease-in-out hover:shadow-lg"
        onClick={onToggle}
      >
        {/* Header Section */}
        <div className="flex items-center px-5 py-6">
          <img
            src={isExpanded ? "/icon/minus.webp" : "/icon/plus.svg"}
            className={`w-6 h-6 rounded-full transition-transform duration-300 ${
              isExpanded ? "bg-primary" : ""
            }`}
            alt={isExpanded ? "Collapse" : "Expand"}
          />
          <h1 className="ml-3 font-sora text-lg font-semibold leading-[25.09px]  -[-0.48px] text-left">
            {question}
          </h1>
        </div>

        {/* Expandable Content */}
        <div
          ref={contentRef}
          className="overflow-hidden transition-max-height duration-1000 ease-in-out"
          style={{ maxHeight: "0px" }}
        >
          <div className="px-5 py-4 text-white">
            <p className="mb-4">{paragraphfirst}</p>
            {listItems.length > 0 && (
              <ul className="list-disc pl-5 mb-4">
                {listItems.map((item, index) => (
                  <li key={index} className="mb-2">
                    {item}
                  </li>
                ))}
              </ul>
            )}
            <p>{paragraph}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faqs;
