import React, { useState, useEffect } from "react";

import Elevate from "../../components/Elevate";
import Faqs from "../../components/Faqs";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import AOS from "aos";
import "aos/dist/aos.css";
import LoginCard from "../../components/Logincard";
import ForgetPasswordEmail from "../../components/ForgetPasswordEmail";
import ResetPasswordCard from "../../components/ResetPasswordcard";
import Notification from "./Notification";

function ResetPassword() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [rotateX, setRotateX] = useState(17.2483);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const scrollValue = 0.3;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const scrollDiff = scrollTop - lastScrollTop;

      if (scrollDiff > 0) {
        if (rotateX > 0) {
          setRotateX(Math.max(0, rotateX - scrollValue));
        }
      } else {
        if (rotateX < 30.2483) {
          setRotateX(Math.min(26.2483, rotateX + scrollValue));
        }
      }

      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, rotateX]);
  return (
    <div className="body">
      <div className="signup-div">
        <Navbar />
        <ResetPasswordCard />
      </div>
      <Elevate />

      <Footer />
    </div>
  );
}
export default ResetPassword;
