import React, { useContext, useEffect, useRef, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { URL } from "../constants/constant";
import Notification from "./Notification";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { getNfgMerchantToken, postRequest } from "../backendServices/ApiCalls";

function Varification2FaAll({
  faModalOpen,
  closeverificationModal,
  localtoken,
}) {
  // console.log("🚀 ~ Varification2FaAll ~ localtoken:", localtoken)
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (otp.join("").length === 6) {
      handleOtpSubmit();
    }
  }, [otp]);

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Focus next field
      if (index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (!otp[index]) {
        // Focus previous field if empty
        if (index > 0) {
          otpRefs.current[index - 1]?.focus();
        }
      } else {
        // Clear current field
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      otpRefs.current[index + 1]?.focus();
    }
  };
  const handleOtpClick = (index) => {
    otpRefs.current[index]?.focus();
  };
  const handleOtpSubmit = async () => {
    if (otp.join("").length == 6) {
      setMessage("");
    } else {
      setMessage("Please enter a valid 6-digit OTP.");
      return;
    }
    setLoading(true);
    const otpToken = otp.join("");
    try {
      const response = await axios.post(
        `${URL}/auth/verifyfalogin`,
        { token: otpToken },
        {
          headers: { Authorization: `Bearer ${localtoken?.token}` },
        }
      );
      if (response.data.isVerified) {
        localStorage.setItem("token", localtoken?.token);
        setUser(localtoken?.user);
        navigate("/");
        getNfgMerchantToken(
          (response) => console.log("NFG Merchant Token:", response),
          (error) =>
            console.error("Error in getting NFG Merchant Token:", error)
        );
        Notification.showNotification(
          "success",
          "Success",
          "OTP verified. Login Successfully"
        );
        closeverificationModal();
      } else {
        Notification.showNotification("error", "Error", "Verification failed.");
        setOtp(Array(6).fill(""));
      }
      setLoading(false);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      Notification.showNotification(
        "error",
        "Error",
        "An error occurred during verification."
      );
      setOtp(Array(6).fill(""));
      setLoading(false);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleOtpSubmit();
    }
  };
  const Images = {
    close: "./icon/CDM.svg",
  };
  return (
    faModalOpen && (
      <div className="fixed inset-0 px-1 flex items-center justify-center z-50  backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
        <div className="w-full max-w-[738px] px-4 sm:px-6 py-8 bg-[#211E34E5] border-[#FFFFFF26] border rounded-3xl relative">
          {/* <div className="relative flex justify-end items-center p-4">
            <img
              src={Images.close}
              width={20}
              height={20}
              onClick={closeverificationModal}
              className="absolute cursor-pointer"
              alt="close-icon"
            />
          </div> */}
          <div className="flex justify-between">
            <p className="font-sora text-xl font-bold leading-[20.16px] text-left text-white">
              Enter Code
            </p>
            <img
              src={Images.close}
              width={20}
              height={20}
              onClick={closeverificationModal}
              className="cursor-pointer"
              alt="close-icon"
            />
          </div>
          <p className="font-helvetica text-[12px] font-normal leading-[24px] text-left text-accent2">
            Please check your Authenticator and enter the code.
          </p>
          <div className="rounded-lg bg-[#3F3C4F] px-4 py-7 mt-6">
            <p className="font-title-medium font-sora text-title-medium text-center">
              Enter Code
            </p>
            <div className="flex justify-center mt-2 space-x-2 sm:space-x-4">
              {otp.map((digit, index) => (
                <input
                  key={`otp-${index}`}
                  ref={(el) => (otpRefs.current[index] = el)}
                  type="text"
                  value={digit}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleOtpKeyDown(e, index)}
                  onClick={() => handleOtpClick(index)}
                  maxLength="1"
                  className="h-10 w-10 sm:w-16 sm:h-16 border border-accent2 text-primary bg-transparent rounded-md text-center font-bold text-lg sm:text-2xl"
                />
              ))}
            </div>
          </div>
          {message && (
            <p className="text-center mt-2 text-red-500">{message}</p>
          )}
          <div className="flex justify-between my-8">
            <button
              className={`px-[10%] border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
              onClick={closeverificationModal}
            >
              Cancel
            </button>
            <button
              className={`${
                loading ? "cursor-not-allowed opacity-50" : ""
              } px-[2%] sm:px-[4%] border border-primary text-[12px] sm:text-[16px] text-black font-semibold bg-primary py-2 rounded`}
              onClick={handleOtpSubmit}
              disabled={loading}
            >
              {loading ? (
                <img
                  src="/icon/loader.svg"
                  alt="Loading..."
                  className="w-7 mx-auto h-7 animate-spin"
                />
              ) : (
                "Confirm Verify"
              )}
            </button>
          </div>
        </div>
      </div>
    )
  );
}

export default Varification2FaAll;
