import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import Faqs from "../../components/Faqs";
import Footer from "../../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../components/Navbar";

function FaqsModal() {
  AOS.init();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef(null);

  const [springProps, set] = useSpring(() => ({
    from: { transform: "perspective(600px) rotateX(-10deg) translateY(-80px)" },
    to: { transform: "perspective(600px) rotateX(0deg) translateY(0px)" },
    config: { tension: 100, friction: 20 },
  }));

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  useEffect(() => {
    set({
      transform: isScrollingDown
        ? "perspective(600px) rotateX(0deg) translateY(0px)"
        : "perspective(1000px) rotateX(10deg) translateY(80px)",
    });
  }, [isScrollingDown, set]);
  return (
    <div className="body">
      <Navbar/>
      <div>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
          className="font-sora text-[16px] text-primary font-normal pt-[3%] leading-[22.4px]  -[4.8px] text-center"
        >
          HAVE QUESTION?
        </p>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="200"
          className="font-sora text-[34px] sm:text-[44px] mt-3 font-semibold leading-[49.28px]  -[-0.88px] text-center"
        >
          Frequently Asked <br />
          Question
        </p>
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="400"
          className="mt-4 flex mb-20 flex-col gap-6"
        >
          <Faqs
             question="How do I get started with AlgoX Capital? "
             paragraphfirst="Getting started with AlgoX Capital is quick and easy:"
             listItems={[
               "Create an account and complete our secure verification process (KYC)",
               "Fund your account: We accept deposits starting from 50€",
               "Our algorithms start working automatically - you can track your performance through our intuitive dashboard",
             ]}
            isExpanded={expandedIndex === 0}
            onToggle={() => handleToggle(0)}
          />{" "}
          <Faqs
           question="How much money do I need to start with AlgoX Capital?"
           paragraphfirst="You can start using AlgoX Capital
 with a minimum deposit of 50€. However, due to exchange minimum order sizes, some
 trading strategies may not be triggered with smaller deposits. Our Pro Plan (minimum
 2,500€) allows full access to all trading strategies. Note: This information reflects technical
 platform limitations and is not financial advice.
 " isExpanded={expandedIndex === 1}
            onToggle={() => handleToggle(1)}
          />{" "}
          <Faqs
            question="Is my money safe using AlgoX Capital?"
            paragraphfirst="Security is our top priority. Your funds are safe with
  the world's second-largest cryptocurrency exchange Bybit. Additionally, our platform adheres
  to strict EU regulations, including MiCA compliance, ensuring the highest standards of
  security and transparency. Note: This information reflects technical platform limitations and
  is not financial advice, remember that crypto is a high-risk investment product.
  " isExpanded={expandedIndex === 2}
            onToggle={() => handleToggle(2)}
          />
          <Faqs
            question="I have a problem, how can I contact the AlgoX Capital Team?  "
            paragraphfirst="We're here to help! Here are the ways you can reach our support team:"
            listItems={[
              "FAQ Section: Before reaching out, check our comprehensive FAQ section. You might find an immediate answer to your question there.",
              <span>
                Email Support: For any questions or issues, please contact us at{" "}
                <a
                  className="text-[#523eba]"
                  href="mailto:support@algox.capital?subject=Support%20Request"
                >                support@algox.capital
                </a>
                . Our dedicated team aims to respond within 24 hours.
              </span>,
            ]}
            paragraph="We're committed to providing you with prompt and effective support to ensure your experience with AlgoX Capital is smooth and satisfactory."
            isExpanded={expandedIndex === 3}
            onToggle={() => handleToggle(3)}
          />{" "}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default FaqsModal;
