import React, { useContext, useState, useEffect, useRef } from "react";
import { UserContext } from "../contexts/UserContext";
import Notification from "./Notification";
import ErrorIcon from "@mui/icons-material/Error";
import { postRequest } from "../backendServices/ApiCalls";

function ChangeEmailModal({ isOpen, onClose }) {
  const { user, setUser } = useContext(UserContext);
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const otpRefs = useRef([]);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpMessage, setOtpMessage] = useState("");
  const [seconds, setSeconds] = useState(53);
  const [otpLoading2, setOtpLoading2] = useState(false);
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    if (expired) {
      setSeconds(0);
    }
  }, [expired]);

  useEffect(() => {
    let interval;
    if (seconds > 0 && !expired) {
      interval = setInterval(() => {
        setSeconds((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setExpired(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [seconds, expired]);

  const resetOtpStates = () => {
    setOtp(Array(6).fill(""));
    setOtpMessage("");
    setOtpLoading(false);
    setSeconds(53);
    setExpired(false);
  };
  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Focus next field
      if (index < otp.length - 1) {
        otpRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const updatedOtp = [...otp];
      if (!otp[index]) {
        // Focus previous field if empty
        if (index > 0) {
          otpRefs.current[index - 1]?.focus();
        }
      } else {
        // Clear current field
        updatedOtp[index] = "";
        setOtp(updatedOtp);
      }
    } else if (e.key === "ArrowLeft" && index > 0) {
      otpRefs.current[index - 1]?.focus();
    } else if (e.key === "ArrowRight" && index < otp.length - 1) {
      otpRefs.current[index + 1]?.focus();
    }
  };

  const handleOtpClick = (index) => {
    otpRefs.current[index]?.focus();
  };
  const handleChangeEmail = async () => {
    setLoading(true);
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      setLoading(false);
      return;
    }

    setMessage("");
    postRequest(
      "yes",
      "auth/send-email-otp",
      { newEmail },
      (response) => {
        if (response.status === 200) {
          Notification.showNotification(
            "success",
            "Success",
            "OTP has been sent to your email"
          );
          setShowOtpModal(true);
          resetOtpStates();
        } else {
          setMessage(response.data.message);
        }
        setLoading(false);
      },
      (error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred. Please try again.";
        setMessage(errorMessage);
        setLoading(false);
      },
      {}
    );
  };

  const handleVerifyOtp = async () => {
    setOtpLoading(true);
    if (!navigator.onLine) {
      Notification.showNotification(
        "error",
        "Network Error",
        "Please check your internet connection and try again."
      );
      setOtpLoading(false);
      return;
    }
    setOtpMessage("");
    postRequest(
      "yes",
      "auth/verify-email-otp",
      { emailotp: otp.join(""), newEmail },
      (response) => {
        if (response.status === 200) {
          Notification.showNotification(
            "success",
            "Success",
            "Email has been changed successfully"
          );
          const updatedEmail = response.data.newEmail;
          setUser((prevUser) => ({
            ...prevUser,
            email: updatedEmail,
            varifiedemailotp:null
          }));
          setShowOtpModal(false);
          setNewEmail("");
          onClose();
        } else {
          setOtpMessage(response.data.message);
        }
        setOtpLoading(false);
      },
      (error) => {
        // Handle error response
        setOtpMessage("Invalid OTP. Please try again.");
        setOtpLoading(false);
        setOtpLoading2(false);
      },
      {}
    );
  };
  const handleOtpPaste = (e) => {
    e.preventDefault(); // Prevent default paste behavior
    const pastedData = e.clipboardData.getData("Text").slice(0, otp.length); // Limit to OTP length
    const otpArray = pastedData.split("");

    // Update the OTP state with the pasted values
    setOtp((prevOtp) => {
      const updatedOtp = [...prevOtp];
      otpArray.forEach((char, idx) => {
        updatedOtp[idx] = char;
      });
      return updatedOtp;
    });
    const nextInput = document.querySelector(
      `input#otp-input-${otpArray.length}`
    );
    if (nextInput) {
      nextInput.focus();
    }
  };

  const handleResendCode = () => {
    setOtpLoading2(true);
    resetOtpStates();
    handleChangeEmail(); // Resend the OTP
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleChangeEmail();
    }
  };
  const maskedEmail = (() => {
    const email = user?.email || "No email available";
    const [localPart, domainPart] = email.split("@");
    const firstPart = localPart.slice(0, 3);
    const maskedLocal = `${firstPart}***`;
    const maskedDomain =
      domainPart?.length > 3 ? `${domainPart.slice(0, 3)}***` : domainPart;
    return `${maskedLocal}@${maskedDomain}`;
  })();
  useEffect(() => {
    if (otp.join("").length === 6) {
      handleVerifyOtp();
    }
  }, [otp]);
  const Images = {
    close: "./icon/CDM.svg",
  };
  return (
    isOpen && (
      <>
        {!showOtpModal && (
          <div className="fixed inset-0 flex items-center justify-center z-50 mx-4 backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
            <div className="w-full max-w-md lg:max-w-lg px-4 sm:px-6 lg:px-8 h-auto rounded-3xl bg-[#211E34E5] border-[#FFFFFF26] border mx-auto relative">
              <div className="relative flex justify-end items-center py-4">
                <img
                  src={Images.close}
                  width={20}
                  height={20}
                  onClick={() => {
                    onClose();
                    setMessage("");
                    setNewEmail("");
                  }}
                  className="absolute top-4  cursor-pointer"
                  alt="close-icon"
                />
              </div>
              <h1 className="text-accent font-helvetica  mt-2 mb-0 text-center text-2xl sm:text-3xl lg:text-4xl font-bold leading-tight">
                Change Email
              </h1>
              <div className="max-w-full sm:max-w-[488px] mt-6 sm:mt-4 pt-6  sm:pt-4 h-auto  mx-auto rounded-3xl">
                {/* <div className="w-10 h-10 sm:w-12 sm:h-12 lg:w-16 lg:h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
                  <img
                    src="/icon/yellowProfile.png"
                    className="w-5 h-5 sm:w-6 sm:h-6"
                    alt="Profile"
                  />
                </div> */}
                <div className="mx-4 sm:mx-8 mt-4">
                  <p className="text-accent  mb-2 text-base sm:text-lg font-semibold leading-relaxed block">
                    New Email
                  </p>
                  <input
                    type="email"
                    name="newEmail"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    onKeyPress={handleKeyPress}
                    className="bg-black w-[96%] h-10 ml-[1%] rounded text-primary font-semibold "
                  />
                </div>
                {message && (
                  <p className="text-center mt-2 text-red-500">{message}</p>
                )}

                <div className="mx-[4%] flex gap-6 my-8">
                  <button
                    className={`w-full mx-auto border border-[#FFFFFF80] text-[#FFFFFF80] py-2 rounded`}
                    onClick={() => {
                      onClose();
                      setMessage("");
                      setNewEmail("");
                    }}
                  >
                    Back
                  </button>
                  <button
                    className={`${
                      loading ? "cursor-not-allowed opacity-50" : ""
                    } w-full border border-primary text-black font-semibold bg-primary py-2 rounded`}
                    onClick={handleChangeEmail}
                    disabled={loading}
                  >
                    {loading ? (
                      <img
                        src="/icon/loader.svg"
                        alt="Loading..."
                        className="w-7 mx-auto h-7 animate-spin"
                      />
                    ) : (
                      " Change Email"
                    )}
                  </button>
                </div>
                {/* <button
                  disabled={loading}
                  onClick={handleChangeEmail}
                  className={` border-primary text-black font-semibold bg-primary w-[85%] md:w-[60%] mt-6 mb-6 rounded text-sm md:text-md py-3 md:py-4 mx-auto flex items-center justify-center ${
                    loading ? "cursor-not-allowed opacity-50" : ""
                  }`}
                >
                  {loading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-5 md:w-7 mx-auto h-5 md:h-7 animate-spin"
                    />
                  ) : (
                    "Change Email"
                  )}
                </button> */}
              </div>
              {/* <button
                onClick={onClose}
                className="absolute top-2 right-2 p-1 rounded-full text-accent hover:bg-gray-800 focus:outline-none"
              >
                X
              </button> */}
            </div>
          </div>
        )}

        {showOtpModal && (
          <div className="fixed inset-0 flex items-center justify-center mx-4 z-50 backdrop-blur-sm bg-black bg-opacity-50 animate-fadeIn">
            <div className="w-full max-w-md sm:max-w-lg lg:max-w-xl px-4 sm:px-6 lg:px-8 h-auto rounded-3xl bg-[#211E34E5] border-[#FFFFFF26] border mx-auto relative">
              <div className="relative flex justify-end items-center py-4">
                <img
                  src={Images.close}
                  width={20}
                  height={20}
                  onClick={() => setShowOtpModal(false)}
                  className="absolute top-4 cursor-pointer"
                  alt="close-icon"
                />
              </div>
              <h1 className="text-accent font-helvetica  mt-6 mb-0 text-center text-xl sm:text-2xl lg:text-3xl font-bold leading-tight">
                Email Verification Code
              </h1>
              <div className="max-w-full sm:max-w-[488px] mt-6 mb-6 sm:mt-4 pt-6 sm:pt-4 h-auto  mx-auto rounded-3xl">
                {/* <div className="w-10 h-10 sm:w-12 sm:h-12 lg:w-16 lg:h-16 rounded-2xl items-center mx-auto justify-center flex bg-primary bg-opacity-20">
                  <img
                    src="/icon/yellowProfile.png"
                    className="w-5 h-5 sm:w-6 sm:h-6"
                    alt="OTP"
                  />
                </div> */}
                {otpMessage && (
                  <div className="flex items-center bg-red-200 border border-red-500 mx-auto rounded-lg p-2 mt-2 shadow-md">
                    <div className="flex mx-auto gap-4">
                      <ErrorIcon className="text-center text-red-500 " />{" "}
                      {/* Material-UI icon */}
                      <p className="text-red-600">{otpMessage}</p>
                    </div>
                  </div>
                )}
                <div className="flex justify-center mb-4 sm:mb-4 items-center">
                  <img src="/icon/email.svg" alt="Email Icon" />
                  <p className="font-poppins text-sm  font-normal ml-2 leading-5 sm:leading-6 text-left">
                    A verification code will be sent to off{" "}
                    <span className="font-poppins text-sm font-bold leading-5 sm:leading-6 text-left">
                      {maskedEmail}
                    </span>
                  </p>
                </div>
                <div className="mx-4 sm:mx-8">
                  <div className="flex space-x-2 sm:space-x-3 justify-center">
                    {otp.map((digit, index) => (
                      <input
                        key={`otp-${index}`}
                        ref={(el) => (otpRefs.current[index] = el)}
                        type="text"
                        value={digit}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => handleOtpChange(e, index)}
                        onKeyDown={(e) => handleOtpKeyDown(e, index)}
                        onClick={() => handleOtpClick(index)}
                        onPaste={handleOtpPaste}
                        maxLength="1"
                        className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 border border-primary text-primary bg-black rounded-md text-center text-lg sm:text-xl"
                      />
                    ))}
                  </div>
                  <p className="font-poppins text-xs sm:text-sm mt-2 font-normal leading-5 sm:leading-6 text-end">
                    Didn’t able to receive verification code?
                    {expired ? (
                      <span className="text-red-500 font-semibold">
                        {" "}
                        Code Expired
                      </span>
                    ) : (
                      <span> {seconds}s</span>
                    )}
                  </p>
                  <p className="font-poppins text-xs sm:text-sm font-semibold mt-8 sm:mt-10 pb-2  leading-5 sm:leading-6 text-start text-primary">
                    Having Problems With Verification?
                  </p>
                </div>
                {/* <button
                  disabled={otpLoading}
                  onClick={expired ? handleResendCode : handleVerifyOtp}
                  className={`bg-primary w-[85%] md:w-[60%] mt-4  text-black font-medium rounded-xl text-sm md:text-md py-3 md:py-4 mx-auto flex items-center justify-center ${
                    otpLoading ? "cursor-not-allowed opacity-50" : ""
                  }`}
                >
                  {otpLoading ? (
                    <img
                      src="/icon/loader.svg"
                      alt="Loading..."
                      className="w-5 md:w-7 mx-auto h-5 md:h-7 animate-spin"
                    />
                  ) : expired ? (
                    "Resend Code"
                  ) : (
                    "Verify OTP"
                  )}
                </button> */}
                <div className="flex">
                  <button
                    disabled={otpLoading}
                    onClick={!expired ? handleVerifyOtp : null}
                    className={` border-primary text-black font-semibold bg-primary w-[35%] md:w-[30%] mt-4  rounded text-sm md:text-md py-3 md:py-4 mx-auto flex items-center justify-center ${
                      otpLoading ? "cursor-not-allowed opacity-50" : ""
                    }`}
                  >
                    {otpLoading ? (
                      <img
                        src="/icon/loader.svg"
                        alt="Loading..."
                        className="w-5 md:w-7 mx-auto h-5 md:h-7 animate-spin"
                      />
                    ) : (
                      "Verify OTP"
                    )}
                  </button>
                  {expired && (
                    <button
                      disabled={otpLoading2}
                      onClick={expired && handleResendCode}
                      className={` border-primary text-black font-semibold bg-primary w-[35%] md:w-[30%] mt-4 rounded text-sm md:text-md py-3 md:py-4 mx-auto flex items-center justify-center ${
                        otpLoading ? "cursor-not-allowed opacity-50" : ""
                      }`}
                    >
                      {otpLoading2 ? (
                        <img
                          src="/icon/loader.svg"
                          alt="Loading..."
                          className="w-5 md:w-7 mx-auto h-5 md:h-7 animate-spin"
                        />
                      ) : (
                        "Resend Code"
                      )}
                    </button>
                  )}
                </div>
              </div>
              {/* <button
                onClick={() => setShowOtpModal(false)}
                className="absolute top-2 right-2 p-1 rounded-full text-accent hover:bg-gray-800 focus:outline-none"
              >
                X
              </button> */}
            </div>
          </div>
        )}
      </>
    )
  );
}

export default ChangeEmailModal;
