import React, { useContext } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRounded from "@mui/icons-material/CancelRounded";
import { UserContext } from "../contexts/UserContext";
import { Link, useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";

function Pricing() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = () => {
    if (user) {
      navigate("/dashboard", { state: { showModal: true } });
    } else {
      navigate("/dashboard");
    }
  };
  return (
    <div>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora text-[16px] text-primary mt-44 font-normal leading-[22.4px]  -[4.8px] text-center"
      >
        PRICING
      </p>
      <p
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
        className="font-sora  mt-3 px-3 font-semibold leading-[49.28px]  -[-0.88px] text-center text-[34px] sm:text-[50px]"
      >
        Right Plan For You
      </p>
      <div
        className="flex flex-wrap gap-4 justify-center items-center px-2 mt-12"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="400"
      >
        <div className=" p-2 bg-[#222222] rounded-3xl max-w-80 w-full">
          <div className="h-52 w-full rounded-3xl bg-[#000000BF]">
            <div className="gap-4 flex justify-center py-[23%] items-center">
              <div className="size-16 bg-[#B0A4FF29] rounded-2xl justify-center items-center flex">
                <EmailIcon style={{ color: "#FFBF00", fontSize: 30 }} />
              </div>
              <div className="mt-3">
                <p className="font-sora text-base font-semibold leading-[1.12rem]">
                  Need help choosing
                </p>
                <Link to="/contactus">
                  <div className="flex gap-2 mt-1 ">
                    <p className="font-sora text-sm font-normal leading-[21px] text-primary">
                      Contact us
                    </p>
                    <img
                      src="/icon/contactarrow.png"
                      className="size-2 my-auto"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-9 my-7">
            <p className="font-sora text-[16px] font-semibold text-center">
              Access Trading Algorithm
            </p>
            <p className="font-sora text-[16px] font-semibold text-center">
              Access Dashboard
            </p>
            <p className="font-sora text-[16px] font-semibold text-center">
              Access to new future services
            </p>
            <p className="font-sora text-[16px] font-semibold text-center">
              Costumer support
            </p>
            <Tooltip
              title="Example: For a profit of €100, we automatically deduct our performance fee:
€35 (Basic), €10 (Pro) or €5 (VIP)"
              arrow
              placement="top"
            >
              <div className="flex gap-2 justify-center hover:text-primary items-center">
                <p className="font-sora text-[16px] font-semibold">
                  Performance fee
                </p>
                <img
                  src="/icon/planeyeicon.png"
                  alt="fees"
                  className="w-4 h-4 "
                />
              </div>
            </Tooltip>
            {/* <p className="font-sora text-[16px] font-semibold text-center">
              Broker fee
            </p> */}
          </div>
        </div>
        <div className=" p-2 bg-[#222222] rounded-3xl max-w-80 w-full">
          <div className="h-52 w-full rounded-3xl bg-[#000000BF]">
            <div className="py-6 px-2 sm:px-4">
              <p className="font-sora text-[20px] font-semibold ">Basic</p>
              <p className="font-sora text-[14px] font-normal text-[#9B9B9B] ">
                Investment from
              </p>
              <p className="font-sora text-[29px] font-semibold">
                50€ - 2.499€
              </p>
              <button
                className="bg-transparent rounded-xl w-full py-3 border mt-2 border-primary"
                onClick={handleClick}
              >
                <p className="font-sora text-[10px] sm:text-[16px] text-primary text-center font-medium leading-[23.94px] ">
                  Invest Now
                </p>
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center pb-6 flex-col">
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 2 }}
            />
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
            />
            <CancelRounded sx={{ color: "gray", fontSize: 40, marginTop: 3 }} />
            <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-5">
              Basic
            </p>
            <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-6">
              35%
            </p>
            {/* <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-5">
              0.038%
            </p> */}
          </div>
        </div>
        <div className=" p-2 bg-[#222222] rounded-3xl max-w-80 w-full">
          <div className="h-52 w-full rounded-3xl bg-[#000000BF]">
            <div className="py-6 px-2 sm:px-4">
              <div className="flex justify-between">
                <p className="font-sora text-[20px] font-semibold ">Pro</p>
                <div className="bg-[#222222]  rounded-xl h-6 mt-[-8px] mr-[-4px]">
                  <p className="font-sora text-[12px] px-2 pt-0.5 font-semibold text-primary">
                    POPULAR
                  </p>
                </div>
              </div>
              <p className="font-sora text-[14px] font-normal text-[#9B9B9B] ">
                Investment from
              </p>
              <p className="font-sora text-[29px] font-semibold">
                2.500€ - 14.999€
              </p>
              <button
                className="bg-transparent rounded-xl w-full py-3 border mt-2 border-primary"
                onClick={handleClick}
              >
                <p className="font-sora text-[10px] sm:text-[16px] text-primary text-center font-medium leading-[23.94px] ">
                  Invest Now
                </p>
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center pb-6 flex-col">
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 2 }}
            />
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
            />
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
            />
            <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-5">
              Premium
            </p>
            <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-6">
              10%
            </p>
            {/* <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-5">
              0.038%
            </p> */}
          </div>
        </div>
        <div className=" p-2 bg-[#222222] rounded-3xl max-w-80 w-full">
          <div className="h-52 w-full rounded-3xl bg-[#000000BF]">
            <div className="py-6 px-2 sm:px-4">
              <p className="font-sora text-[20px] font-semibold ">VIP</p>
              <p className="font-sora text-[14px] font-normal text-[#9B9B9B] ">
                Investment from
              </p>
              <p className="font-sora text-[29px] font-semibold">15.000€</p>
              <button
                className="bg-transparent rounded-xl w-full py-3 border mt-2 border-primary"
                onClick={handleClick}
              >
                <p className="font-sora text-[10px] sm:text-[16px] text-primary text-center font-medium leading-[23.94px] ">
                  Invest Now
                </p>
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center pb-6 flex-col">
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 2 }}
            />
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
            />
            <CheckCircleRoundedIcon
              sx={{ color: "#FFBF00", fontSize: 40, marginTop: 3 }}
            />
            <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-5">
              VIP Personal
            </p>
            <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-6">
              5%
            </p>
            {/* <p className="font-sora text-[20px] font-semibold text-[#9B9B9B] mt-5">
              0.038%
            </p> */}
          </div>
        </div>
      </div>
      <p className="max-w-[1260px] px-4 font-sora text-[14px] mt-4 text-[#9b9b9bfb] font-normal leading-[19.6px] text-left mx-auto">
        Standard exchange trading fees apply, just as they would if you traded
        directly on ByBit
      </p>
    </div>
  );
}

export default Pricing;
